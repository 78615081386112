$main-butt-width:170px;
$main-butt-height:90px;

@mixin white-stroke {
    text-shadow:
    -1px -1px 0 #fff,
    1px -1px 0 #fff,
    -1px 1px 0 #fff,
    1px 1px 0 #fff; 
    // text-shadow: #fff;
  }

  @mixin trans-stroke {
    text-shadow:
    -1px -1px 0 rgba(255, 255, 255, 0),
    1px -1px 0 rgba(255, 255, 255, 0),
    -1px 1px 0 rgba(255, 255, 255, 0),
    1px 1px 0 rgba(255, 255, 255, 0); 
    // text-shadow: #fff;
  }

.main-butt-container{
    // background: red;
    display: flex;
    flex-wrap: wrap;
}

.main-butt{
    background:white;
    width: $main-butt-width;
    height: $main-butt-height;
    margin: 0px 14px 14px 0px;;
    cursor: pointer;
    overflow:hidden;

    box-shadow: $box-shadow;
}

.main-butt .background-image{
    height: 100%;
    width: auto;
    float: right;
    -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.5s;
}
.main-butt:hover img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .main-butt:hover .content {
    // @include trans-stroke;
    background-color: rgba(104, 147, 190, 0.301);
  }

.main-butt .content{
    width: 100%;
    height: 100%;
    position: relative;
    @include white-stroke;
    white-space: nowrap;
    padding: 10px;
}



.main-butt .count{
    // @include white-stroke;
    font-size: 26px;
}

.main-butt .name{
    // @include white-stroke;
    font-size: 14px;
    margin: 0px;
}