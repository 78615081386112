.q-gen-item, .q-options{
    .q-item-wrapper{
        height: 100%;
    }
    &:not(.is-sub){
        &>.q-item-wrapper{
            display: flex;
            flex-direction: column;
            border-radius: 4px;
        }
    }
    .collapsed-space{
        label:hover{
            cursor: pointer;
        }
    }
    
    .c-quest-input{
        min-width: 235px;
    }
    .c-quest-select{
        min-width: 100px;
    }
    .q-label {
        font-size: 11px;
        color: #667280;
        &:not(.is-sub){
            color: #394047;
            font-size: 12px;
            font-weight: 600;
        }
    }
    .collapsed-space{
        height: 20px;
        width: 100%;

        span{
            cursor: pointer;
        }
    }

    .main-row{
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        cursor: pointer;
        padding: 5px;
        border: 1px solid transparent;

        &:hover{
            border-bottom: 1px solid rgba(95, 90, 109, 0.397);
            background-color: #f1f1f18a;
        }
    }
    .main-row:hover, .main-row.selected{
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        background-color: #f1f1f1da;
        border-radius: 3px;
        cursor: pointer;
    }
 
}
.q-manage-answer-options{
    .adder-wrapper{
        display: flex;
    }
}
.q-manage-condition{
    padding: 10px;
    // border: 1px solid gray;
    .adder-wrapper{
        display: flex;
    }

    .ew-rows{
        display: flex;
        flex-wrap: wrap;
        padding: 20px;
    }
    .ew-row{
        display: flex;
        position: relative;
        padding: 5px;
        align-items: center;
        background: whitesmoke;
        margin: 2px;

        .ew-question, .ew-operator, .ew-answer {
            margin-right: 10px;
        }
        .ew-question, .ew-operator, .ew-answer, .ew-main {
            font-size: 12px;
            font-weight: 600;
        }

        .ew-question{

        }
        .ew-operator{

        }
        .ew-answer{

        }
    }

    

    .ew-close{
        outline: none;
        // position: absolute;
        // top: 0px;
        // right: -10px;
        border-radius: 50%;
        border: 1px solid;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        padding: 0px;
        color: #8a374d;
        background: white;

        &:disabled{
            display: none;
        }

        &:hover:not(:disabled){
          background:#8a374d;
          color: white;
        }
    }
}
