@mixin button-group{
  font-size: 12px;
  white-space: nowrap;
  align-items: center;
    &>*:not(.non-trigger):not(.ember-basic-dropdown-content-wormhole-origin):not(.non-trigger-content),&>*>.trigger, &>*>.tg-search, &>.non-trigger>.trigger, &>.non-trigger>button, .dd-trigger{
        color: $primary-color;
        // border: 1px solid rgba(34, 36, 38, 0.15);
        background: white;
        border-color: transparent;
        box-shadow: 0px -1px 8px #b5b1b166;
        outline: none;
        padding: 6px 16px;
        display: flex;
        align-items: center;
        height: 32px;
        transition: background-color .5s;
        margin: 0px;

        &:hover{
            background: rgba(128, 128, 128, 0.09);
        }

        &:last-child:not(:first-child){
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
        }
    }

    .md-label{
        white-space: nowrap;
    }

    &>md-input-container, &>.non-trigger>md-input-container{
         padding: 0px 20px !important;
         margin: 0px;

         .md-input {
             color: rgba(0, 0, 0, 0.87);
             border-width: 0px;
         }

         >*{
             width: 100%;
         }

         md-select-value{
             border: none;
         }

         .md-errors-spacer{
             flex: 0;
         }

         label{
             text-align: center;
             margin-bottom: 6px;
             font-size: 14px;
             color: #909090;
         }

         md-select.md-default-theme:not([disabled]):focus .md-select-value, md-select:not([disabled]):focus .md-select-value {
             border-bottom-color: transparent;
             border-bottom-width: 0px;
             color: rgba(0, 0, 0, 0.87);
        }
     }


    i{
      margin-left: 10px;
    }

    &>*>.trigger{
        height: 100%;
        width: 100%;
    }
    &>.tg-search, &>*>.tg-search{
        min-width: 280px;
    }

    .active{
        background: $primary-color !important;
        color: white !important;
    }


    &>:first-child:not(.non-trigger):not(.non-trigger-content), &>:first-child>.trigger, &>.non-trigger:first-child>:first-child:not(.non-trigger), &>:first-child.dd-trigger{
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;

        &:not(:last-child){
            border-right: 0px;
        }
    }

    &>:not(:first-child):not(:last-child):not(.non-trigger):not(.non-trigger-content), &>:not(:first-child):not(:last-child)>.trigger, &>.non-trigger>:not(:first-child):not(:last-child):not(.non-trigger), &>:not(:first-child):not(:last-child)>.dd-trigger{
        border-right: 0px;
    }
    &>:last-child:not(.non-trigger):not(.ember-basic-dropdown-content-wormhole-origin):not(.non-trigger-content), &>:last-child>.trigger, &>.non-trigger>:last-child:not(.non-trigger), .last-child, &>:last-child>.dd-trigger{
        &:not(:first-child){
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
    }

    .md-whiteframe-1dp, .md-whiteframe-z1 {
        box-shadow: none;
   }



}

.tools-group{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin:6px 0px;
    position: relative;
    flex-wrap: wrap;

    .left{
        flex: 1;
        display: flex;
        flex-direction: row;
        @include button-group;
    }
    .center{
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        @include button-group;
    }
    .right{
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        @include button-group;
    }

}
