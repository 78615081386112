.con-display-search{
    padding: 10px 30px;
    padding-bottom: 0px;
    display: flex; 
    //position: relative;
    

    .search-input{
        border-radius: 14px;
        padding: 8px;
        border: 1px solid #bad3f1;
        background: #f8f8f8;
        font-size: 14px;
        width: 100%;
        outline: none;
        color: #3a73dd;
        // z-index: 9;
    }

    .results{
        position: absolute;
        top: 45px;
        z-index: 10;
        width: calc(#{100%} - 60px);
        // z-index: 9;
        max-height: 300px;
        overflow-y: auto;

        background: white;
        border: 1px solid #dfdcdc;
        border-top: 0px;
        border-radius: 0px 0px 14px 14px;
        box-shadow: 1px 1px 15px 0px rgba(66, 161, 255, 0.49);
        

        .no-result{
            margin: 10px;
        }

        >div:not(.error-cont){
            &:hover{
                // cursor: pointer;
                filter: brightness(0.95);
            }
            padding-left: 10px;
            padding-right: 10px;
            background: white;
        }
    }

    // .alert-unfocus{
    //     z-index: 9;
    // }
}