.timeline-container {
    background: #f7f7f7;
    padding-bottom: 30px;
    padding-top: 30px;

    .unfocus{
        position:fixed;
        background: $inline-unfocus-bg;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        z-index: 1;
    }

    .no-records {
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        color: #a2a2a2;
    }

    .right-submit {

        position: absolute;
        right: 46px;

        &button, button{
            font-size: 12px;
            padding: 8px 16px;
        }
    }

    button.right-submit {
        font-size: 12px;
        padding: 8px 16px;
    }

    md-input-container .md-errors-spacer {
        float: right;
         min-height: 0px;
        min-width: 1px;


    }
    .edit-page > *:not(:first-child):not(.submit-button){
        margin-left: 16px !important;
    }

    // input{
    //     outline: none;
    //     border: 1px solid #f9f5f5 !important;
    //     padding: 3px 6px;
    //     border-radius: 3px;
    //     font-size: 14px;
    //     height: 30px;
    //     box-shadow: 2px 1px 9px 1px rgba(129, 154, 189, 0.18);
    //     &[type='text']{
    //         color: #777373;
    //     }
    //     &[type='time']{
    //         color: rgb(55, 94, 167);;
    //     }

    // }

    ul {
        margin: 0;
        list-style: none;
        position: relative;
        padding: 1px 100px;
        padding-right: 50px;
        color: rgb(55, 94, 167);
        font-size: 13px;

        &:before {
            content: "";
            width: 1px;
            height: 100%;
            position: absolute;
            border-left: 2px dashed #b5b4b4;
        }

        li {
            position: relative;
            margin-left: 30px;

            &.selectable{
                cursor: pointer;

                &:hover{
                    .content-wrapper{
                        background: #f3f6fb;
                    }
                    .check{
                        border-color: #7cb2f3;
                    }
                }

            }

            &.selected{
                .content-wrapper{
                    border: 2px solid #4086dc;
                }

                .check{
                    color: #4086dc;
                    border-color: #7cb2f3;
                }
            }

            .check{
                position: absolute;
                top: 35%;
                right: -20px;
                font-size: 28px;
                color: #d0d0d0;
                border: 1px solid #c7ced6;
                height: 33px;
                width: 33px;
                border-radius: 3px;
            }

            .time{

                .from-time{
                    color: #0f67c4;
                }

                .to-time{
                    color: #5f7394;
                }
            }

            &.on-edit{
                z-index: 2;

                .manage-right, .manage-bottom{
                    display: none;
                }

                .time{
                    color: #2f60ff;
                }
                .cancel{
                    display: inline-flex;
                }
            }

            &:not(.on-edit){
                >.content {
                    &:hover{
                        .manage-right{
                            display: flex;
                        }
                    }
                }
                .cancel{
                    display: none;
                }
            }

            &:not(:last-child) {
                margin-bottom: 60px;
            }

            > span {
                width: 2px;
                height: 100%;
                background: #7587f3;
                left: -30px;
                top: 0;
                position: absolute;

                &:before, &:after {
                    content: "";
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    border: 5px solid #afcdf5;
                    position: absolute;
                    background: #2679e3;
                    left: -6px;
                    top: -6px;
                }
            }

            span{
                &:after {
                    top: 100%;
                }
            }

            > .content {
                position: relative;

                .content-wrapper{
                    margin-left: 10px;
                    margin-right: 30px;
                    background-color: rgb(255, 255, 255);
                    padding: 14px;
                    border-radius: 4px;
                    box-shadow: 9px 1px 17px 3px rgba(89, 116, 230, 0.07);



                    .edit-page{
                        display: none;
                    }

                    &.on-edit{
                        z-index: 12;
                        .page{
                            // display: none;
                        }
                        .edit-page{
                            display: flex;
                            align-items: center;
                        }
                    }

                }

                .manage-right{
                    position: absolute;
                    display: none;
                    right: 0;
                    top: 0;
                    height: 100%;
                    // display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                }
            }

        }

    }

    div{
        .title{
            font-size: 16px;
            margin-bottom: 6px;
            color: #257ae3;
        }

        .duration{
            font-weight: 600;
            font-size: 12px;
            color: #948f8f;
            margin-top: 6px;
        }
        .break {
            font-size: 13px;
            float: right;
            .break-label{
                // margin-left: 6PX;
            }
        }

        .info {
            font-weight: 300;
        }
        // div > div {
        //     margin-top: 5px;
        // }
    }
    span.time {
        height: 100%;

        span {
            position: absolute;
            font-size: 11px;
            left: -63px;
            font-weight: bold;

            &:first-child {
                top: 0;
            }

            &:last-child {
                top: 100%;
            }
        }
    }

    .add, .remove, .edit, .cancel{

        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #f1f0f0;
        font-size: 10px;
        display: flex;
        justify-content: center;
        color: #949393;
        box-shadow: 1px 1px 7px 0px rgba(94, 124, 250, 0.17);
        background: white;
        outline: none;
        position: relative;

        .tl-tooltip{
            display: none;
            position: absolute;
            right: -29px;
            color: #4a8ee2;
            width: 20px;
            font-size: 12px;
            top: 2px;
        }

        &:hover{
            color: #85b7f7;
            box-shadow: 1px 1px 14px 1px rgba(111, 140, 251, 0.322);

            .tl-tooltip{
                display: block;
            }
        }
    }

    .cancel{
        position: absolute;
        top: -18px;
        right: 12px;
        border-width: 0px;

        &:hover {
            background: #fdc9d4;
            color: red;
        }
    }
    .manage-bottom{
        position: absolute;
        bottom: -30px;
        left: 50%;
    }
    .manage-middle{
      position: absolute;
      top: 0px;
      left: 50%;
    }

    md-checkbox{
        .md-icon {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-transition: 240ms;
            transition: 240ms;
            position: absolute;
            top: 0;
            left: 0;
            width: 16px;
            height: 16px;
            border-width: 1px;
            border-style: solid;
            border-radius: 2px;
        }

        &.md-checked .md-icon:after {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            left: 4.66667px;
            top: 2.22222px;
            display: table;
            width: 5.66667px;
            height: 9.33333px;
            border-width: 2px;
            border-style: solid;
            border-top: 0;
            border-left: 0;
            content: '';
        }
    }

    .md-checkbox.md-default-theme.md-checked .md-icon, md-checkbox.md-checked .md-icon {
        background: linear-gradient(135deg, #0e83dd 0%, #5961f9 100%);
    }

    md-checkbox.md-default-theme:not(.md-checked) .md-icon, md-checkbox:not(.md-checked) .md-icon {
        border-color: rgb(107, 156, 216);
        background: #f5f4f4;
    }


}
