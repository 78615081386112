.object-viewer{
    margin-bottom: 1rem;
}
.ov-property{
    // margin: 10px 3px 0px 3px;

    &.vertical{
        // display: flex;
        // flex-direction: row;

        .ov-value{
            // margin-top: 0px;
            // margin-left: 6px;

        }
    }

    &.edit-mode{
      .ov-key{
        color: #699add !important;
      }
    }

    // &:not(:first-child){
        // margin-left: 10px;
        // margin-left: 40px;
    // }
    
    .md-errors-spacer{
        min-height: 0px;
    }

}


.ov-value-wrapper {
    display: flex;
    align-items: center;

    &.border{
        border: 1px solid #f0f3f7  !important;
        padding: 5px;
        border-radius: 2px;
        // background: #fafafb;
    }

    .ov-button{
      margin-left: 10px;
      margin-top: 6px;
      margin-bottom: 6px;

      font-size: 12px;
      border: none;
      padding: 5px 10px;
    }
}

.ov-key{
    font-size: 15px;
    margin: 0px;
    color: $dark-text;
    font-weight: 600;
}

.ov-value{
    margin: 3px 0px 0px 0px;
    font-size: 13px;
    color: $dark-text-light;
}

.form-container .ov-property:not(:first-child) {
    margin-left: 6px;
    // background: red;
}
