// .active-serve-list{
//     //layout-row layout-align-center-center margin-bottom-20
//     display: flex;
//     flex-direction: row;
//     justify-content: center;


//     :not(:first-child) {
//         margin-left: 20px;
//     }
// }

// .dashboard-count {
//     box-shadow: 3px 3px 13px 6px rgba(77, 123, 175, 0.22);;
//     border-radius: 4px;
//     padding: 16px 16px;
//     cursor: pointer;
//     display: flex;
//     align-items: center;
//     @include primary-gradient-bg-diagonal2;
//     filter: brightness(1.2);

//     &:hover{
//         filter: brightness(1.3)
//     }

//     .infos{
//         margin-left: 16px;

//         .p-name {
//             font-size: 30px;
//             color: #ffffff;
//             margin: 0px;
//         }
    
//         .v-purpose {
//             font-size: 14px;
//             color: #aacaff;
//             margin: 4px 0px 0px 2px;
//         }
    
//         .duration {
//             margin: 0px;
//             margin-top: 7px;
//             text-align: right;
//             color: white;
//         }
    
        
//     }

//     svg.icon > * {
//         fill: #ececec;;
//     }

    
// }



.dashboard-item{
    .title{
      display: flex;
      padding: 0px;
      margin: 0px 0px;
  
      .mark{
        width: 3px !important;
        padding: 0px;
        margin-right: 10px;
        background: #10a1f5;
      }
      .text{
        flex: 1;
        font-size: 13px;
        color: #467F8C;// #5A5D60;
        font-weight: 600;
        text-transform: uppercase;
        padding-left: 20px;
      }
  
    }
    .highcharts-title{
      font-size: 12px !important;
      font-weight: 600;
      color: gray !important;
    }
  }
  
  .dashboard-count {
    height: 100%;
    background: white;
    /* -webkit-box-shadow: 3px 3px 13px 6px rgba(77, 123, 175, 0.22); */
    box-shadow: 0px 0px 6px 0px rgba(147, 185, 227, 0.15);
    border-radius: 10px;
    padding: 12px 0px 20px 0px;
    // cursor: pointer;
    // filter: brightness(1.2);
    // &:hover{
    //   background: #fbfafa;
    //   // filter: brightness(1.3)
    // }
    .title{
      display: flex;
      padding: 0px;
      margin: 2px 0px;
    }
    .count-center{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 10px;
  
      .count-wrapper{
        flex:1;
        .count {
          font-size: 50px;
          font-weight: 400;
          color: #75B2BF;
          margin: 0px;
          // margin-left: 10px;
        }
      }
      .infos{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        // margin-top: 10px;
        .text{
          flex: 1;
          font-size: 13px;
          color: #467F8C;// #5A5D60;
          font-weight: 600;
          text-transform: uppercase;
        }
        .sub-wrapper{
          display: flex;
          align-items: center;
          font-size: 16px;
          margin-top: 3px;
  
          &.negative{
            .sublabel{
              color: #9e6868;
            }
            .subvalue{
              color: #e85959;
            }
          }
  
          .sublabel {
            color: #8a97a7;
            font-weight: 400;
            margin: 2px 10px;
            /* text-transform: uppercase; */
            font-size: 15px;
          }
          .subvalue {
            color: #467F8C;
            background-color: #CEECF2;
            border-radius: 10px;
            padding: 2px 5px;
            font-size: 12px;
          }
        }
      }
    }
    .discription{
      font-size: 12px;
      margin-top: 5px;
      text-align: center;
      color: white;
    }
  
    svg.icon > * {
      fill: #ececec;;
    }
  }
  
  // .count-item-0{
  //   .count{
  //     color: #f9368f !important;
  //   }
  // }
  // .count-item-1{
  //   .count{
  //     color: #00d4b7 !important;
  //   }
  // }
  // .count-item-2{
  //   .count{
  //     color: #056ce0 !important;
  //   }
  // }
  // .count-item-3{
  //   .count{
  //     color: #7d48fb !important;
  //   }
  // }
  // .count-item-4{
  //   .count{
  //     color: #24cb39 !important;
  //   }
  // }
  // .count-item-5{
  //   .count{
  //     color: #f93dea !important;
  //   }
  // }
  // .count-item-6{
  //   .count{
  //     color: #ead902 !important;
  //   }
  // }
  // .count-item-7{
  //   .count{
  //     color: #2464ab !important;
  //   }
  // }
  // .count-item-8{
  //   .count{
  //     color: #d25fc3 !important;
  //   }
  // }
  // .count-item-9{
  //   .count{
  //     color: #1c03a0 !important;
  //   }
  // }
  // .count-item-10{
  //   .count{
  //     color: #00bfea !important;
  //   }
  // }
  // .count-item-11{
  //   .count{
  //     color: #e83232 !important;
  //   }
  // }
  // .count-item-12{
  //   .count{
  //     color: #645ab3 !important;
  //   }
  // }
  // .count-item-13{
  //   .count{
  //     color: #5300d6 !important;
  //   }
  // }
  // .count-item-14{
  //   .count{
  //     color: #3a863c !important;
  //   }
  // }
  
  .chartWrap{
    border-radius: 10px;background: #ffffff;
    padding: 16px 0px;
    box-shadow: 0px 0px 6px 0px rgba(147, 185, 227, 0.15);
  }
  .chartTitle{
    margin-top: 8px;
    color: #333333;
    font-size: 18px;
    fill: #333333;
  }