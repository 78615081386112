
.table1{

}
.table1 table{
}
.table1 th{
    font-size: 12px;
    // text-align: center;
}
.table1 td,th{
    padding: 10px;
}



.table1 thead th{
    font-size: 12px;
    border-width: 0px 0px 1px 0px;
    border-style: solid;
    border-color: rgba(206, 206, 206, 0.568);
}

.table1 tbody td{
    font-size: 12px;
    border-width: 0px 0px 1px 0px;
    border-style: solid;
    border-color: rgba(182, 181, 181, 0.568);
}

.table-inline-edit{
    padding: 0px;
    overflow: hidden;
    border-radius: 500rem;
    border: solid 1px #dededf;
    display: flex;
    flex-direction: row;
    width: 86px;

    input{
      &.warning{
        background: #ffcec5;
        color: #4e4a4a;
        font-weight: 600;
      }
        margin: 0px;
        padding: .67861429em .3em;
        border: none;
        outline: none;
        flex: 1;
        width: 57px;
    }

    .tie-open{
        font-size: 12px;
        padding: 0px 8px;
        height: auto;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        cursor: pointer;

        &:hover{
            background: #f6f6f6;
        }
    }
}
