$item-one-bg:linear-gradient(135deg, #dd9817 0%, #f1b517 100%);

// .dash-item-top{
//     margin-top: 14px;
// }

.welcome-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .smile{
      *{
        fill: gray;
      }
    }

    .greetings{
      display: flex;
      align-items: center;
      margin-top: 10px;
      >*{
        margin: 0px;
      }

      .text{
        color: gray;
        font-size: 32px;
        font-weight: 100;
      }

      .user{
        font-weight: 600;
        font-size: 32px;
        margin-left: 16px;
        text-transform: uppercase;
        color: gray;
      }


    }
}

