.progress-list-item {
    height: 46px;
    position: relative;
    border: 0px solid #d3d7de;
    border-bottom-width: 1px;


    &:last-child{
        border-bottom-width: 0px;
    }

    .pli{
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 6px;



        .pli-icon {
            width: 30px;
            height: 30px;
            background: #547be8;
            color: white;
            text-align: center;
            padding: 6px;
            font-weight: 600;
            font-size: 10px;
            flex-shrink: 0;
            border-radius: 6px;
            margin: 0px;
        }

        .pli-content {
            flex: 1;
            padding: 6px;
            white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

            .pli-main {
                flex: 1;
                font-weight: 500;
                font-size: 12px;
                margin: 0px;
                color: #696868;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .pli-progress-text {
            margin: 0px;
            font-weight: 500;
            font-size: 12px;
            color: #8a8787;
            margin-right: 18px;
        }

        .pli-remove {
            width: 16px;
            height: 16px;
            padding: 0px;
            font-size: 9px;
            background: transparent;
            color: #f76f6f;
            border: 2px solid;
            border-radius: 50%;
            outline: none;
        }
    }

    .pli-progress-bg {
        position: absolute;
        width: 45%;
        height: 100%;
        background: #eaeef5;
    }


}
