

// .has-main-table {


  .index-col{
      width: 10px;
  }
  .trans-date-col{
      width: 140px;
  }

  .total-cost-col{
    width: 150px;
    text-align:center;
  }

  .payment-col{
    width: 150px;
    text-align:center;
  }

  .fixed-manage-row-col{
    width: 200px;
    .li-actions{
      width: 100%;
      justify-content: flex-end;
    }
  }

// }
