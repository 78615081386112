.dashboard-skeleton{
  .dashboard-item{
    &>div{
      background: #ffffff66;
      box-shadow: none;
    }
  }

  .dashboard-count{
    height: 140px;
  }

  .chartWrap{
    height: 360px;
  }

  .title, .count-center{
    display: none !important;
  }
}
