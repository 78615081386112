.sign-in{
    width: 100vw;
    height: 100vh;
    background-color: yellow;
  }
  
  .signin-left-col{
      height: 100%;
      // background: #2B58A2; 553f01
      @include primary-gradient-bg-diagonal
      // /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#6382ff+0,123597+100 */
      // background: rgb(99,130,255); /* Old browsers */
      // background: -moz-linear-gradient(-45deg, #ee9ae5  0%, rgba(18,53,151,1) 100%); /* FF3.6-15 */
      // background: -webkit-linear-gradient(-45deg, #ee9ae5 0%,rgba(18,53,151,1) 100%); /* Chrome10-25,Safari5.1-6 */
      // background: linear-gradient(135deg, #ee9ae5 0%,rgba(18,53,151,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ee9ae5', endColorstr='#123597',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    }
  
  .signin-left-ad{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
      justify-content: center;
    /* background-image: url("image/s_pattern.png"); */
  }
  
  .sign-form{
      display: flex;
      font: 100% $font-stack;
      font-size: 15px;
      margin: 0 37%;
    flex-direction: column;
  }
  
  .stack {
      position: absolute;
    }
  
  .signin-content{
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: white;
    }
  
    // .icon-button {
    //     -webkit-border-radius: 5;
    //     -moz-border-radius: 5;
    //     border-radius: 5px;
    //     border-width: 0px;
    //     color: #ffffff;
    //     font-size: 20px;
    //     // background:$primary-color;
    //     padding: 15px 20px 15px 20px;
    //     text-decoration: none;
    //   }
      
      // .icon-button:hover {
      //   background: $primary-light-color;
      //   text-decoration: none;
      // }
    
      .icon-button i {
        margin-left: 20px;
      }
    
      .sign-refer{
          margin: 20px auto 0 auto;
            font-size: 15px;
            color: #545556;
      }
    
      .sign-toggle{
        font-size: 20px;
          color: $primary-color;
    }
    
    .margin-top{
      margin-top:6px; 
    }

    .sign-in-button{
      @include primary-gradient-bg-sing-in;
    }