
//colors
$accent-color:rgb(209, 152, 1);

$font-stack:    Helvetica, sans-serif;
// $primary-color: #2B58A2;
// $primary-light-color: #4771b7;
$primary-color: #2C4C59;
$primary-light-color: #4973e9;
$primary-light-color2: #6c8eed;
$primary-light-color3: #c5d2f7;
$primary-white-color: #CEECF2;
$primary-white-color2: #CEECF2;
$dark-text: rgb(102, 102, 102);
$dark-text-light: #767676;
$green-color:#1abc9c;
$red-color: #e74c3c;
$blue-color: #2196F3;
$warn-color: #f1993d;
$off-color: #95a5a6;
$gray-button-background:#ecf0f1;
$gray-button-forecolor:#686868;
$box-shadow:0px 0px 30px 0px rgba(24, 50, 92, 0.13);
$mini-menu-background:#3e73cb;

$g-primary-color: #0a9ff7;
$g-secondary-color: #0648d6;
$dark-content-bg: #f8f8f8;
$darker-content-bg: #ececec;

//dimen
$quick-action-button-width:45px;

$app-header-height:50px;
$app-footer-height:40px;
$app-breadcrumbs-height:30px;
$app-toolbar-height:auto;
$app-quick-action-width:220px;
$app-brand-width:150px;

$edge-radius:8px;

$container-border-radius:5px;


$side-menu-width:220px;
$side-menu-collapsed-width:47px;
$side-menu-item-height:50px;
$side-menu-item-sub-height: 30px;
$app-mini-workspace-width:400px;
$quick-action-width:220px;

$tablet-bp:1200px;

$unfocus-bg: rgba(117, 123, 162, 0.32);
$inline-unfocus-bg: rgba(117, 123, 162, 0.32);
$mini-side-menu-width:180px;

$primary-gradient-bg1: linear-gradient(-45deg, #467F8C 10%, #2C4C59 100%);
$primary-gradient-bg2: linear-gradient(135deg, #75B2BF 0%, #467F8C 100%);

@mixin dropdown-bg{
    background: rgb(255, 255, 255);
    border-radius: 6px;
    border: solid 1px #d4d4d4;
    box-shadow: 0px 0px 18px 1px rgba(24, 50, 92, 0.22);
}

// @mixin primary-gradient-bg{
// /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#6382ff+0,123597+100 */
// background: rgb(99,130,255); /* Old browsers */
// background: -moz-linear-gradient(left, #6382ff 0%, #123597 100%); /* FF3.6-15 */
// background: -webkit-linear-gradient(left, rgba(99,130,255,1) 0%,rgba(18,53,151,1) 100%); /* Chrome10-25,Safari5.1-6 */
// background: linear-gradient(to right, rgba(99,130,255,1) 0%,rgba(18,53,151,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6382ff', endColorstr='#123597',GradientType=1 ); /* IE6-9 */
// }

// @mixin primary-gradient-bg{
// /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#6382ff+0,123597+100 */
// background: rgb(99,130,255); /* Old browsers */
// background: -moz-linear-gradient(left, #6382ff 0%, #123597 100%); /* FF3.6-15 */
// background: -webkit-linear-gradient(left, rgba(99,130,255,1) 0%,rgba(18,53,151,1) 100%); /* Chrome10-25,Safari5.1-6 */
// background: linear-gradient(to right, rgba(99,130,255,1) 0%,rgba(18,53,151,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6382ff', endColorstr='#123597',GradientType=1 ); /* IE6-9 */
// }

@mixin primary-gradient-bg-diagonal{
    background-image: $primary-gradient-bg1;
}

@mixin primary-gradient-bg-diagonal2{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ee9ae5+0,5961f9+100 */
    background: $primary-gradient-bg2;
}

@mixin primary-gradient-bg-sing-in{
    background: linear-gradient(135deg, #a3669d 0%,rgba(89,97,249,1) 100%);
}

@mixin primary-gradient-bg-lt{
// background: linear-gradient(135deg, #7071df 0%, #4b42aa 100%);
background: linear-gradient(135deg, #1064c2 0%, #5193e6 100%)
}
// @mixin primary-gradient-bg-lt-diagonal{
// /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#5b81eb+0,2658e5+100 */
// background: rgb(91,129,235); /* Old browsers */
// background: -moz-linear-gradient(-45deg, rgba(91,129,235,1) 0%, rgba(38,88,229,1) 100%); /* FF3.6-15 */
// background: -webkit-linear-gradient(-45deg, rgba(91,129,235,1) 0%,rgba(38,88,229,1) 100%); /* Chrome10-25,Safari5.1-6 */
// background: linear-gradient(135deg, rgba(91,129,235,1) 0%,rgba(38,88,229,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5b81eb', endColorstr='#2658e5',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
// }
@mixin primary-gradient-bg-lt-diagonal{
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#5b81eb+0,2658e5+100 */
// background: rgb(91,129,235); /* Old browsers */
// background: -moz-linear-gradient(-45deg, rgba(91,129,235,1) 0%, rgba(38,88,229,1) 100%); /* FF3.6-15 */
// background: -webkit-linear-gradient(-45deg, rgba(91,129,235,1) 0%,rgba(38,88,229,1) 100%); /* Chrome10-25,Safari5.1-6 */
// background: linear-gradient(135deg, rgba(91,129,235,1) 0%,rgba(38,88,229,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5b81eb', endColorstr='#2658e5',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
background: linear-gradient(135deg, #7071df 0%, #4b42aa 100%);
}

@mixin accent-gradient-bg-lt-diagonal{
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#5b81eb+0,2658e5+100 */
  // background: rgb(91,129,235); /* Old browsers */
  // background: -moz-linear-gradient(-45deg, rgba(91,129,235,1) 0%, rgba(38,88,229,1) 100%); /* FF3.6-15 */
  // background: -webkit-linear-gradient(-45deg, rgba(91,129,235,1) 0%,rgba(38,88,229,1) 100%); /* Chrome10-25,Safari5.1-6 */
  // background: linear-gradient(135deg, rgba(91,129,235,1) 0%,rgba(38,88,229,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5b81eb', endColorstr='#2658e5',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  background: linear-gradient(135deg, #7071df 0%, #4b42aa 100%);
}


@mixin comp-gradient-bg{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#c6a348+0,c49a2f+100 */
    background: rgb(198,163,72); /* Old browsers */
    background: -moz-linear-gradient(top, rgba(198,163,72,1) 0%, rgba(196,154,47,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(198,163,72,1) 0%,rgba(196,154,47,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(198,163,72,1) 0%,rgba(196,154,47,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c6a348', endColorstr='#c49a2f',GradientType=0 ); /* IE6-9 */
}
