.confirm-options{
    display: flex;
    flex-wrap: wrap;
    button{
        // background: rgb(160, 159, 159);
        padding: 10px;
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        border: 1px solid $primary-light-color3;
        background: $gray-button-background;
        color: $gray-button-forecolor;
        min-height: 40px;


        &:hover{
            
            color: $primary-color;
            border-color: $primary-color;

            .co-title{
                opacity: (1);
            }
        }

        .co-title{
            opacity: (.9);
            color: $primary-light-color2;
            font-weight: 600;
            white-space: nowrap;
        }
        .co-description{
            // color: $dark-text-light;
            font-size: 12px;
            margin-top: 4px;
        }
    }
}