.file-preview{
  display: flex;
  justify-content: center;

  &:not(.file-preview-thumbnail){
    // background: red;
    height: 100%;
    .ext-preview{
      height: 200px;
      width: 151px;
      border-top-left-radius: 37px;
      p{
        font-size: 28px;
      }
    }

  }
  &.file-preview-thumbnail{

  }

  .fp-wrapper{
    overflow: hidden;
    width: fit-content;

    &:not(.fp-thumbnail){
      height: 100%;
    }

    &.fp-thumbnail{

    }

    &.fp-thumbnail-small{
      width: 50px;
      height: 50px;

      .ext-preview{
        height: 45px;
        width: 40px;
        border-top-left-radius: 8px;
        p{
          font-size: 14px;
        }
      }
    }

    img{
      height: 100%;

      &.not-image{
        display: none;
      }
      // width: fit-content;
      // height: 100%;
    }
    .ext-preview-wrapper{
      height: 100%;
      display: flex;
      align-items: center;
      .ext-preview{
        background: #829bf3;
        display: flex;
        justify-content: center;
        align-items: center;

        &.not-ext{
          display: none;;
        }

        p{
          color: white;
          font-weight: 600;
          text-align: center;
        }
      }
    }


  }

}
