

.top-bar-container{
    display: flex;
    justify-content: space-between;
    background: #ffffff; //$primary-white-color;
    width: 100%;
    height: 60px;//$app-header-height;
    padding: 5px;
}

.top-bar-brand{
    width:300px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.top-bar-brand .logo{
    padding: 6px;
    height: $app-header-height;
} 

.top-bar-brand .name{
    font-weight: bold;
    color: $primary-color;
    height: $app-header-height;
}

.top-bar-info{
    padding:6px 20px;
    justify-content: flex-end;
    // width:300px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .dd-trigger{
        outline: none !important;
    }
}

.top-bar-facility-badge{
    background-color: #467F8C;
    color:#FFF;
    margin:10px 5px;
    padding:6px 12px ;
    font-size: 12px;
    border-radius: 10px;
    text-transform: uppercase;
}

.top-bar-user-badge{
    background-color: #75B2BF;
    color:#FFF;
    margin:10px 50px 10px 5px;
    padding:6px 12px ;
    font-size: 12px;
    border-radius: 10px;
    text-transform: uppercase;
}

.top-bar-user-badge a{
    text-decoration: none;
    color: #2C4C59;
    cursor: pointer;
}


.round-primary{
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    border-color: $primary-color;
    padding: 2px;
    overflow: hidden;
}



