.page-not-found-page{
  @include primary-gradient-bg-diagonal;
  height: 100vh;
  width: 100vw;
  display: flex;;
  align-items: center;
  justify-content: center;

  .not-found{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:50px;
    flex-grow: 0;
    flex-shrink: 0;
    .icon{
      *{
        fill: rgba(255, 255, 255, 0.4117647058823529);
      }
    }
    .four-o-four {
      color: white;
      font-weight: 600;
      font-size: 250px;
      margin: -30px 0px;
      flex-grow: 0;
      flex-shrink: 0;
    }

    .info {
        color: rgba(255, 255, 255, 0.78);
        font-size: 20px;
    }

    .home-button {
      a{
        color: #003c74;
      }
      border-radius: 20px;
      border-width: 0px;

      font-size: 16px;
      margin-bottom: 10px;
      background: linear-gradient(135deg, #bee3ff 0%, #a5aafe 100%);
      padding: 13px 24px;
      text-decoration: none;
      font-weight: 400;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      outline: none;
      white-space: nowrap;
      justify-content: center;
      box-shadow: 3px 3px 13px 1px rgba(94, 124, 250, 0.369);
    }

  }
}
