form{
  font-size: 14px;

    md-radio-button:not(:first-child) {
        margin-left: 20px;
    }
}
.form-container{
    font-size: 12px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.form-container{
    font-size: 12px;
    padding: 10px 10px;
    width: 100%;
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
}

.bill .form-container{
    padding: 0px 10px;
    margin: -25px 0px;
}

.form-container>.ember-view{
    // width: 100%;
    // margin-left: 6px;
    // margin-right: 6px;
    // background: rebeccapurple;
}

.form-input{
    // background: red;
    margin: 6px 6px !important;
    min-width: 100%;
}

.fi-width{
    width: 220px;
}

.fi-options-width{
    width: 90px;
    min-width: 20px;
    // background: red;
}

.fi-margin-left{
    margin-left: 32px;
}

.fi-margin-right{
    margin-right: 32px;
}
.fi-info-text{
    color: #7f8c8d;
    margin: 0px 6px 20px 0px;
    font-size: 14px;
}
