.q-edit-item-input{
    flex: 1;
    display: flex;
    margin-left: 10px;
    &>*{
        flex: 1;
    }



    .radio-group{
        display: flex;
        align-items: center;
        .radio-item{
            display: flex;
            align-items: center;
            label{
                padding-right: 4px;
            }
            &:not(:first-child){
                margin-left: 20px;
            }
            &>*{
                margin: 0px;
                &:not(:first-child){
                    // padding-left: 4px;
                }
            }
        }
    }
}
