.c-chips{

  .c-chips-wrapper{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .c-chip{
      background: $clear-gray;
      padding: 5px 8px;
      padding-right: 15px;
      margin-right: 15px;
      position: relative;
      display: flex;
      align-items: center;

      .c-close {
        outline: none;
        position: absolute;
        top: 0px;
        right: -10px;
        border-radius: 50%;
        border: 1px solid;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        padding: 0px;
        color: #867b7e;
        background: white;

        &:hover{
          background:#8a374d;
          color: white;
        }
      }
    }

    .c-new-wrapper{
      display: flex;
      align-content: center;
      .c-add{
        color: #439e8e;
        border: none;
        padding: 5px;
      }
    }
    .add-input{
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    input{
      padding: 4px;
    }
    .classic-component{
      margin-right: 0px;
    }

  }
}