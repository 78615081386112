.mini-workspace-toggle{
    height: 35px;
    width: 35px;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
    outline: none;
    border: none;
    @include primary-gradient-bg-diagonal2;
    border-radius: 50%;

    *{
        fill: white;
    }

    &:hover {
        filter: brightness(1.2);
    }
}

.mwt-open{
    right: 16px;
    top: 0;
    position: absolute;
    @media (max-width: $tablet-bp) { 
        top: $app-header-height;
        position: fixed;
    }
}
.mwt-close{
    right: 16px;
    top: 0;
    position: absolute;
    @media (max-width: $tablet-bp) { 
        top: $app-header-height;
        position: fixed;
    }
}

.side-bar-toggle{
    width: 30px;
    height: 30px;
    border-radius: 10%;
    cursor:pointer;
    border: none;
    background: transparent;
    outline: none;

    &:hover {
      background: rgba(255, 255, 255, 0.161);
    }
}


.app-quick-action-button{ 
    height:30px; 
    width:$quick-action-button-width; 
   @include primary-gradient-bg-diagonal2;
    box-shadow: 5px 6px 11px 1px rgba(94, 124, 250, 0.369);
    padding: 5px 6px 5px 15px;
    right:0px; 
    margin-top: 2px;
    position:absolute;
    cursor: pointer;
    border-radius: 35% 0px 0px 35%;
    z-index: 10;
    outline: none;

    &:hover {
        filter: brightness(1.2);
    }
}