.dropdown{ 
    // width: 30px;
    // height: 30px;

    .dd-trigger{
        cursor: pointer;
        // background: red;
    }
}
.dd-wrapper{
    position: absolute;
    // z-index: 11;
    top:10px;

    .dd-triangle{
        width: 45px;
        height: 100px;
        position: absolute;
        top: -90px;
        overflow: hidden;
        left: -7px;

        &:after {
            content: "";
            position: absolute;
            width: 25px;
            height: 25px;
            background: white;
            
            transform: rotate(45deg);
            top: 88px;
            left: 11px;
            box-shadow: -1px -1px 10px -2px rgba(0,0,0,0.5);
        }
        &.hasHeader{
            &:after {
                @include primary-gradient-bg-diagonal2;
            }
        }
    }
   
    
    .dd-dropdown{
        width: 300px;
        position: relative;
        margin-top:10px;
        background: white;
        border-radius: 5px 5px 5px 5px;
        box-shadow: 2px 1px 17px 7px rgba(85, 126, 224, 0.28);
        padding-bottom: 14px;
        // overflow: hidden;

   

        .dd-header{
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;
            @include primary-gradient-bg-diagonal2;
        }

    
        .dd-content{
            width: 100%;
            padding: 6px;
            margin-bottom: 10px;
        
            p{
                font-size: 12px;
            }
        }
    }

    .dd-footer{
        position: absolute;
        bottom: 0;
        margin-bottom: -17px;
        overflow: visible;
        width: 100%;
        display: flex;
        justify-content: center;

        .dd-footer-submit{
            font-size: 14px;
            padding: 10px 22px;
            border-radius: 20px;
            margin: 0px;
        }
    }

    
}


.dd-backdrop{
  background: transparent;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}