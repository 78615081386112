input.classic{
  padding: 5px;
  // background: green;
}
textarea.classic{
  padding: 5px !important;
  min-width: 100%;
  max-width: 100%;
}
.c-input-wrapper{
  display: flex;
}