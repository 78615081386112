

.table-long-text-wrapper{
  max-width: 200px;
  position: relative;
  padding-right: 55px !important;

  .table-long-text{
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
  }

  .btn-show-all{
    top: 0;
    right: 0;
    height: 100%;
    // background: red;
    position: absolute;
    display: flex;
    align-items: center;
    button{
      
      background: transparent;
      color: $primary-color;
      border: none;
      outline: none;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}