.q-options{
    position: relative;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    .close-button{
        position: absolute;
        top: 0px;
        right: 0px;
        height: 20px;
        width: 20px;
        border: none;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        // color: red;
        // float: right;
    }
    .q-o-header{
        position: relative;
    }

    .q-o-content{
        flex: 1;
        overflow-y: auto;
    }

    .AccordionItem{
        padding: 0px !important;

        .AccordionToggle{
            border : 1px solid transparent;
            padding: 8px 5px;
            background: #49688e;
            color: white;
            border-radius: 5px 5px 0px 0px;

            &:hover{
                border-bottom: 1px solid rgb(170, 170, 170);
            }
        }

        .AccordionPanel{
            margin-top: 1px;
            // background: rgba(245, 246, 248, 0.342);
            &.is-active{
                padding: 8px 0px 8px 8px ;
            }
        }
    }

}