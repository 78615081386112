
.component-title{
    &.center{
        display: flex;
        justify-content: center;
    }

    .component-title-container{
      &:not(.no-margin-bottom){
        margin-bottom: 16px;
      }
        margin-top: 46px;
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        &.first
        {
            margin-top: 0px !important;
        }
    }

    .comp-title{
        font-size: 16px;

        margin: 0px 0px 0px 0px;
    }

    .comp-info{
        color: #7f8c8d;
        font-weight: 100;
        font-size: 12px;
        margin: 0px 0px 0px 6px;
    }

}
