
.report-table{
  width: 100%;


  thead {
    tr{
      &.info-header{
        color: gray;
        text-align: center;
      }
      &:not(.info-header){
          background: #5286c2;
          color: white;
      }
      th{
        // border-bottom: 1px solid gray;
        font-size: 12px;
      }
    }
  }

  tr{
    font-size: 12px;

    td{
      padding: 8px 10px;
      white-space: nowrap;

      &.dd-label{
        font-style: italic;
        font-weight: 200;
        color: #24a5e3;
        &.no-dd-data{
          color: gray;
        }
      }

      &.clickable{
        cursor: pointer;
      }
    }
    &:not(:first-child){
      .rt-label{
        // border-top: 1px solid gray;
        // border-bottom-width: 0px !important;
      }
    }
    &:not(.last-child-row){
      td:not(.dd-space){
        border-bottom: 1px solid #d9d6d6;
      }
    }

    &:hover:not(.is-dd-parent){
      td:not(.dd-space){
        background: #f1f9ff;
      }
    }

    &.is-dd-parent{
      &:hover{
        background: #eeebeb;
      }
      td{
        &.rt-label{
          border-bottom-width: 0px !important;
        }

        border-width: 0px !important;
      }
      background: #f8f8f8;
      font-weight: 600;
    }

  }

  .dd-arrow{
    margin: 0px 6px;
    font-weight : 600;

    &.transparent{
      color: transparent;
    }
  }

}

.export-menu-content{
  display: flex;
  flex-direction: column;
  border: 1px solid #bdbdbd;
  .export-menu-item{
    font-size: 12px;
    padding: 10px;
    border: none;
    background: white;
    text-align: left;
    outline: none;

    &:hover{
      background: #efefef;
    }


  }
}
