.operations-list {
    background: #f8fbff;
    padding: 16px;

    // :not(:last-child) .line{
    //     border-bottom: 1px dashed rgba(51, 112, 247, 0.33); 
    // }
    >:not(:last-child){
        // .operation-item{
            border-bottom: 1px solid #eeeeee;
        // }
    }
    

}

.operation-item {
    display: flex;
    // border-bottom: 1px solid #eeeeee;
    padding: 4px 0px;
    

    .operation-name-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        .operation-name{
            margin: 0px;
            font-size: 16px;
            text-transform: capitalize;
            color: #6e6e6e;
            font-weight: 400;
        }
        .operation-description{
            display: flex;
            align-items: center;
            margin-left: 2px;
            margin-top: 6px;
            .icon{
                *{
                    fill: #7288a3;
                }
            }
            p{
                margin-left: 5px;
                font-style: italic;
            }
            color: #7288a3;
            // font-weight: 600;
            font-size: 14px;
        }
    }
    .line-wrapper{
        display: flex;
        align-items: center;
        flex: 1;
        padding: 0px 12px;
        
        .line{
            width: 100%;
            // border-bottom: 1px dashed rgba(51, 112, 247, 0.33); 
            // box-shadow: 0px 2px 8px rgba(52, 125, 213, 0.412);
        }
    }

    .manage {
        display: flex;
        align-items: center;

        .switch-wrapper{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 3px 6px;
            >p{
                margin-bottom: 0px;
                font-size: 12px;
            }

            .md-container{
                margin-right: 0px;
            }
        }
    }
}