
.stats-container{
    background: white;
    display: flex;
    flex-direction: column;
    position: relative !important;

 
    .title{
        .info-cont{

            display: flex;
            flex-direction: column;
            justify-content: center;

            .container-info{
                margin-left: 14px;
                font-size: 14px;
                color: $primary-color;
            }
        }
    }
    .b-crumb-wrapper{
      display: flex;
      .b-crumb{


        &:not(:last-child){
          color: #509ced;
          font-weight: 600;
          cursor: pointer;

          &:hover{
            color: #5383b5;
          }
        }
      }

      .b-crumb-divider{
        font-size: 18px;
        margin: 0px 7px;
        color: #cecece;
      }
    }

}

.stats-container>.title{
    // z-index: 10;
    background: white;
    padding: 20px 20px 14px 0px;
    padding-top: 10px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;

    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

    transition: z-index 0.9s linear;
}

.stats-container>.title.on-alert{
    z-index: 12;

    transition: z-index 0s linear;
}

.container-title-mark{
    background: $primary-light-color;
    width: 2px;
    height: 23px;
    margin-right: 16px;
}

.container-title{
    font-size: 16px;
    color: #2c4c59;
    padding: 10px 0;
    font-weight: 600;
}

.stats-container>.app-fixed-top{
    position: relative !important;
    background: white;
    z-index: 0;
    padding: 6px 14px;
    display: flex;
    flex-direction: column;
}





.stats-container>.app-fixed-bottom{
    position: relative !important;
    background: white;
    z-index: 10;
    padding: 6px 14px;
    font-size: 12px;
}

.stats-container>.content{
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    // padding-top: 14px;
    align-content: flex-start;
    padding-left: 30px;
    padding-right: 30px;
}

.dark-content{
    background: $dark-content-bg;
}

.stats-container,.full-height{

    &>.app-fixed-bottom{
        position: relative !important;
    }

    &>.content{
        flex: 1;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        overflow-y: auto;
        position: relative;
    
        &.content-list{
            background: $dark-content-bg;
    
            &>*:not(.non-list){
                // color: #0a64b624;
                box-shadow: 0px 0px 14px rgba(10, 100, 182, 0.1411764705882353);
            }
        }
    }

    &>.flex-row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
    }
}






.mini-workspace{
    height: 100%;
    display: flex;
    flex-direction: row;
}

.mini-side-menu{
    overflow-y: auto;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: 100%;
    width: $mini-side-menu-width;
    padding: 20px 0px 10px 10px;
    // background: $mini-menu-background;
}

.mini-workspace-content{

    flex-grow: 1;
    padding: 10px 10px 10px 0px;
    height: 100%;
    margin-left: 14px;
    // width: 100%;
}

.mini-side-menu-item-heading{
    color: white;
    font-size: 12.5px;
    margin-bottom: 10px;
    padding: 11px 6px;
    text-transform: uppercase;
    background-color: #467F8C;
}

.mini-side-menu-item{
    // color: $dark-text;
    font-size: 14px;
    padding: 6px 0px 6px 6px;
    margin: 0px 0px 0px 10px;
    /*height: 30px;*/
    display: flex;
    // width: 100%;
    font-weight: 100;
    cursor: pointer;
    color: #dedede;
    transition: all 0.3s ease;
}

.item-hide{
  display: none;
}

.mini-side-menu-item.active{
    background: rgba(255, 255, 255, 0.18);
    // color: white;
    border-radius: 5px 0px 0px 5px;
}
.mini-side-menu-item.active.disabled{
    background: rgba(255, 255, 255, 0.075);
}
.mini-side-menu-item.disabled{
    color: #dedede75;
    cursor: default;
}

.item-text{
    width: 100%;
}
.mini-side-menu-item:not(.disabled):hover{
//     -webkit-transition: color 12s, font-size 20s;
//    -moz-transition: color 12s, font-size 12s;
//      -o-transition: color 12s, font-size 12s;
        // transition: all 60s;
        // font-size: 16px;
        // background: red;
        padding-top: 2px;
}

.mini-menu-container{
    margin: 25px 0px 10px 35px;
    // padding: 6px 0px;
    height: calc(#{100%} - 20px);
    overflow-y:hidden;
    // border-radius: $container-border-radius;
    background-color: #2C4C59;

}

.mini-container{
    height: 100%;
}


.mini-sub-content-container {
    // height: 100%;
    flex: 1;
    height: calc(100% - 30px);
}

.mini-content {
    height: calc(100% - 40px);
}


.float-bottom-right{
    right: 0px;
    bottom: 0px;
    position: absolute;
    margin: 16px;
}
.float-bottom-right > :first-child{
    box-shadow: 0px 0px 20px 2px rgba(24, 50, 92, 0.37);
}

.float-bottom-right > :first-child:disabled {
    box-shadow: 0px 0px 21px 0px rgba(24, 50, 92, 0);
}

.invoice{
    // background: #f7f8fa;
    // width: 595px;
    // height: 842px;
    margin: auto;
}

.verifyThreshold{
    border: 1px solid green !important;
    border-radius:8px !important;
    color: green !important; 
    text-transform:uppercase !important;
    font-size:10px !important;
    padding: 3px !important;
    margin: 0 15px 0 5px !important;
    min-height: 20px !important;
    line-height:0px !important;
}

.thsBtn{
    margin-bottom: 45px;
    float: right;
}

.AccordionItem{
    padding: 15px 10px;
    font-size: 15px;
    border-bottom: 1px #f1f1f1 solid;
}
.AccordionItem:hover{
    /*padding: 15px 10px;
    font-size: 15px;
    border-bottom: 1px #f1f1f1 solid;*/
    // color: #888;
}
