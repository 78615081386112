.q-generator{
    background: white;
    padding: 10px 0px 10px 12px;

    ul{
        margin: 0;
        list-style: none;
        position: relative;
        margin: 0px 10px;
        margin-right: 0px;
        margin-top: 0px;
        padding-right: 0px;
        // color: rgb(55, 94, 167);
        font-size: 13px;

        &:before {
            margin-top: 5px;
            content: "";
            // margin: 30px 1px;
            width: 1px;
            height: 100%;
            position: absolute;
            border-left: 1px dashed #b5b4b4;
        }


  
        .quest-numb{
            position: absolute;
            left: -96px;
            color: #7587f3;
        }


        li{
            margin-left: 50px;
            position: relative;
            // margin-top: 5px;
            // padding-bottom: 10px;

            .q-item-options{
                position: absolute;
                left: -27px;

                .q-item-option {
                    outline: none;
                    background: white;
                    // border: none;
                    border-radius: 50%;
                    height: 20px;
                    width: 20px;
                    color: #3180e4;
                    // box-shadow: 0px 0px 10px #43596b7a;
                }
            }

            > .c-tresh {
                width: 2px;
                height: 100%;
                background: #7587f3;
                left: -50px;
                top: 6px;
                position: absolute;
                cursor: pointer;

                &:hover{
                    background: #5467ce;
                    width: 3px;
                }
    
                &:before{
                    content: "";
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    border: 5px solid #afcdf5;
                    position: absolute;
                    background: #2679e3;
                    left: -6px;
                    top: -6px;
                }
            }
    
            .c-tresh{
                &:after {
                    top: 100%;
                }
            }
        }
    }
}

.q-item-option-list{
    border-radius: 4px;
    background: white;
    padding: 5px;
    box-shadow: 0px 0px 10px #43596b7a;
    &>*{
        background: transparent;
        border: none;
        padding: 8px;
        font-weight: 600;
        text-align: left;
        outline: none;

        i{
            // color: #2679e3;
            margin-right: 5px;
        }

        &:not(:first-child){
            border-top: 1px solid rgb(221, 221, 221);
        }

        &:not(.info):hover{
            background: rgba(55, 62, 70, 0.055);
        }
    }
}
.q-item-option-content {
    display: flex;
    flex-direction: column;
    background: white;

}

.quat{
    width: 35%;
}
.descp{
    width: 65%;
    float: right;
}
.quest-panel-space{
    width: 8px;
}
.quest-panel{    
    width: 270px;
    border:1px solid #eee;
    border-radius: 3px;
    padding: 8px;
    overflow-y: auto;
    margin-right: 6px;
}
