







// .app-header { 
//   width: 100vw;
//   grid-area: header; 
// }

// .app-container{
//   width: 100vw;
//   grid-area: 'app-container-layout';
//   background: white;
//   display: flex;
//   flex-direction: row;
// }

// //Side menu
// .app-side-menu { 
//   // flex-grow: 0;
//   width: $side-menu-width;
//   transition: width 0.3s;
//   background-color: $primary-color;
//  }
 
//  .app-side-menu-collapsed {
//   width: $side-menu-collapsed-width;
// }

// .app-side-menu > .ember-view 
// {
//    height: 100%;
// }

// //workspace
// .app-workspace { 
//   flex-grow: 1;

//   background-color: $primary-white-color2; 
// }

// //quick action
// .app-quick-action { 
//   // flex-grow: 0;
//   transition: width 0.2s;
//   width: $quick-action-width;
//   flex-shrink: 0;
//   flex-grow: 0;
//   background-color: white;
// }



.status-indicator{

}

.status-indicator div{
  height: 12px;
  width: 12px;
  border-radius: 50%;
}

.status-indicator .in{
    background: $green-color;
}

.status-indicator .out{
  background: $red-color;
}

.status-indicator .stay{
  background: $off-color;
}
