$classic-gray: rgba(72, 96, 125, 0.188);// #cecece;
$clear-gray: #f3f3f3;
@import 'c-select.scss';
@import 'c-input.scss';
@import 'c-chips.scss';
@import 'c-button.scss';

.classic-component label, label.classic{
    
    color: #6181ab;
}
.classic-component{
    display: flex;
    // flex-direction: column;
    min-width: 79px;
    margin-right: 10px;
    margin-bottom: 8px;

    .c-label-wrapper{

    }

    .labelInfo{
        font-size: 11px;
        margin-left: 5px;
    }

    &.c-row{
        flex-direction: row;
        align-content: center;

        .c-label-wrapper, .c-input-wrapper{
            display: flex;
            align-items: center;
        }
        label{
            margin-bottom: 0px;
            white-space: nowrap;
            // color: #6181ab;
        }
        input[type='checkbox'] { 
            margin-right: 10px; 
        }

        input{
            margin-top: 0px;
            margin-left: 6px;
        }
    }

    &.c-column{
        // background: red;
        flex-direction: column;
        label{
            margin-bottom: 1px;
            white-space: nowrap;
            // color: #6181ab;
        }
    }
    input{
        flex:1;
    }
}
.clear-gray-bg{
    background: $clear-gray;
    color: #2e776a;

    &:hover{
        color: $clear-gray;
        background: #2e776a;
    }
}


.m-input{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    // height: 20px ;
    min-height: 25px;
    font-size: 12px;
}

.classic-group{
    margin-right: 10px;
}

.ember-basic-dropdown-trigger.classic, .classic-group, input.classic, textarea.classic, button.classic, .c-chip.classic{
    // border: 1px solid $classic-gray;
    // border-width: 1px 1px !important;
    // border-radius: 2px;

    border: 1px solid $classic-gray;//rgba(72, 96, 125, 0.188)
    box-shadow: 1px 1px 7px 0px #5b6e8812;
    border-width: 1px 1px !important;
    border-radius: 2px;
    // background: red;
  }