.sticky-header-table{
  height: 100%;
  position: relative;

  .sticky-header-table-inner{
    height: 100%;
    overflow-y: auto;
  }

  .st-header {
    // display: none;
    position: absolute;
    top: 0;
    width:calc(100% - 30px);
    height: 60px;
    border-top: 1px solid #000;
    background: red;
    z-index: 1;
  }
}
