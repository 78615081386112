.access-m-group-header{
    background: white;
    padding: 6px 10px;
    border-radius: 4px;
    // cursor: pointer;
    // position: relative;
    display: flex;
    align-items: center;


    &.locked{
        background: #f7f7f7;
        border: 1px solid #e1e1e1;
        .icon{
            margin-right: 10px;
            *{
                fill: #666666;
            }
        }

        .group-name{
            font-size: 16px;
            color: #666666;
            margin-bottom: 0px;
            flex: 1;
        }
        
    }

   

    .icon{
        margin-right: 10px;
        *{
            fill: #3582e5;
        }
    }

    .group-name{
        font-size: 16px;
        color: #257ae3;
        margin-bottom: 0px;
        flex: 1;
    }

    .drop-hint{
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 1s linear;
        position: absolute;
        top: 15px;
        left: 50%;
        align-items: center;
        padding-left: 20px;

        *{
            fill: #989797;
            color: #989797;
            font-size: 12px;
        }
        >p{
            margin: 0px;
            margin-right: 10px;
        }
    }

    // .status :hover{
    //     .drop-hint{
           
    //         visibility: visible;
    //         opacity: 1;
    //         display: flex;
            
    //     }
    //     background: red;
    // }

    &:hover{
        .drop-hint{
            visibility: visible;
            opacity: 1;
            display: flex;
        }
    }

    .status {
        background: transparent;
        border: none;
        outline: none;
        border-radius: 25%;
        // background: #f5f3f3;
        width: 40px;
        height: 40px;
        transition: background-color .1s ease;
        
        *{
            // transition: fill .1s ease;
            fill: #a3a3a3;
        }

        &:hover{
            *{
                fill: #0a9ff7;
            }
            background: #f5f3f3;
        }

        &.unlocked{
            *{
                fill: #0a9ff7;
            }

            &:hover{
                *{
                    fill: #a3a3a3;
                }
            }
        }
    }
}

// .operations-list {
//     background: #f8fbff;
//     padding: 16px;

//     // :not(:last-child) .line{
//     //     border-bottom: 1px dashed rgba(51, 112, 247, 0.33); 
//     // }
//     >:not(:last-child){
//         // .operation-item{
//             border-bottom: 1px solid #eeeeee;
//         // }
//     }
//     .operation-item {
//         display: flex;
//         // border-bottom: 1px solid #eeeeee;
//         padding: 4px 0px;
        

//         .operation-name-wrapper{
//             display: flex;
//             align-items: center;
//             .operation-name{
//                 margin: 0px;
//                 font-size: 16px;
//                 text-transform: capitalize;
//                 color: rgb(135, 135, 135);
//                 font-weight: 400;
//             }
//         }
//         .line-wrapper{
//             display: flex;
//             align-items: center;
//             flex: 1;
//             padding: 0px 12px;
            
//             .line{
//                 width: 100%;
//                 // border-bottom: 1px dashed rgba(51, 112, 247, 0.33); 
//                 // box-shadow: 0px 2px 8px rgba(52, 125, 213, 0.412);
//             }
//         }

//         .manage {
//             display: flex;

//             .switch-wrapper{
//                 display: flex;
//                 flex-direction: column;
//                 align-items: center;
//                 margin: 3px 6px;
//                 >p{
//                     margin-bottom: 0px;
//                     font-size: 12px;
//                 }

//                 .md-container{
//                     margin-right: 0px;
//                 }
//             }
//         }
//     }

// }