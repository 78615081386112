.list-view{
  display: flex;
  flex-direction: column;
  &>md-progress-linear{
    opacity: 0.7;
  }
  &.addable{
    position: relative;

    .add-buttons-wrapper{
      position: absolute;
      left: -7px;
      top: 3px;
      display: flex;
      z-index: 2;
      button{
        font-size: 15px;
        font-weight: 600;
        padding: 6px 10px;
        box-shadow: 0px 0px 20px 3px rgba(125, 173, 244, 0.3686274509803922);
        display:flex;
        justify-content: center;

        &:not(:first-child){
          margin-left: 6px;
        }

        i{
          margin-left: 8px;
          font-size: 18px;
        }
      }
    }

    .add-content{
      position: relative;
      padding: 10px;
      padding-top: 36px;
      z-index: 0;
    }
    .list-filter{
      padding-top: 25px;
      overflow-y: auto;
      flex: 1;
    }
  }

  .list-footer{
    display: flex;
    background: white;
    font-size: 14px;
    padding: 10px;
    min-height: 40px;
    align-items: center;
    border-radius: 0px 0px 5px 5px;
    // border-top: 1px solid red;

    >*{
      flex: 1;

      display: flex;
    }
    .left{
      color: #5a7099;
      font-weight: 600;
    }
    .center{
      justify-content: center;
    }
    .right{
      justify-content: flex-end;

      .page-id{
        display: flex;
        align-items: center;padding: 0px 10px;
      }
      button{
        border-radius: 0px;
        @include soft-white-btn;
        // padding: 6px 10px;
        width: 40px;
        font-weight: 600;
      }

      button:first-child{
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      button:last-child{
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

    }
  }

}


.list-item{
    // width: 100%;
    height: 50px;
    border-width: 0px 0px 0px 0px;
    border-style: solid;
    border-color: rgba(96, 96, 96, 0.075);
    margin: 10px;
    //for selectable
    display: flex;
    align-items: center;

    &:not(:first-child){
      border-top-width: 1px;
    }

    &.selected{
      background: #f2f8fd;
      .li-main{
        color: #4086dc;
      }
      .li-sub{
        // color: red !important;
        font-weight: 600 !important;
      }
      .check{
        color: #4086dc;
        // color: white;
        border-color: #7cb2f3;
      }
    }
    .check{
      color: gray;
      border: 1px solid;
      margin-right: 8px;
      font-size: 11px;
      height: 16px;
      width: 16px;
      padding: 1px;
      border-radius: 50%;

      &:not(.disabled){
        cursor: pointer;
      }
    }

    margin: 0px 10px 0px 10px;
    background: white;

    .content-wrapper{
      height: 100%;
      flex: 1;
      padding: 5px 0px;
      display: grid;
      align-items: center;
      grid-row-gap:5px;
      grid-column-gap:5px;
      grid-template-columns: auto 1fr auto auto;
      grid-template-rows: 1fr 15px;
      grid-template-areas:
      "image content more actions"
      "image content  more actions";

      &.clickable:not(.edit-mode):not(.disabled){
          cursor: pointer;
          &:hover{
              background: rgba(105, 104, 104, 0.035);
          }
      }
    }

    &.edit-mode{
      height: auto;
      padding: 10px;
      z-index: 1;
      position: relative;
      z-index: 20;
      border-radius: 6px;
      box-shadow: 3px 2px 20px 2px rgba(165, 165, 165, 0.35);

      md-input-container {
          margin-top: 22px;
          margin-bottom: 8px;
      }
      input{
        padding: 3px 6px;
      }
      .md-input{
        padding: 0px;
      }
      input, .md-input {
          outline: none;
          border: 1px solid #f9f5f5 !important;

          border-radius: 3px;
          font-size: 14px;
          height: 30px;
          box-shadow: 2px 1px 9px 1px rgba(129, 154, 189, 0.18);
      }

      button.submit-button {
          font-size: 12px;
          padding: 8px 16px;
          margin: 0px;
      }
    }


    .md-errors-spacer {
        float: right;
        min-height: 0px;
        min-width: 1px;
    }

}

.unfocus{
  position: fixed;
  background: $inline-unfocus-bg;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}



.li-image{
    width: 40px;
    height: 40px;
    grid-area: image;
    align-items:center;
}

.li-img{
    width: 100%;
    height: 100%;
    background: rgb(85, 110, 157);
    border-radius: 50%;
    margin-right: 6px;
}

.li-content{
    grid-area: content;
    align-items: center;
    font-size: 13px;
}

.li-main{
    font-size: 13px;
    color: $dark-text;
    font-weight: 600;
}

.li-sub{
    font-size: 10px;
    margin-top: 2px;
    color: $dark-text-light;
    font-weight: 400;
}

.li-more{
    grid-area: more;
    font-size: 12px;
    // align-items: center;
    color: #908f8f;
    display:flex;
}

.li-actions{
    grid-area: actions;
    color: #908f8f;
    // align-items: center;
    // display:flex;
    display: inline-flex;
}

.li-button{
    font-size: 12px;
    margin: 0px 3px;
    padding: 2px 6px;
    white-space: nowrap;
    height: fit-content;
}


.list-filter{
    // width: 100%;
    // height: 100%;
    overflow-y: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px;
    // background: #ffffff;
    border-radius: $container-border-radius;
}

.list-filter .list{
    flex: 1;
    overflow-y: auto;
}

.lf-tools{
    width: 100%;
    display: flex;
    flex-direction: row;
    background: white;
}
