.transaction-cell{

  width: 100px;
}

.transaction-cell-comp{
  // min-width: 100px;
  position: relative;
  width: 200px;

  &.hidden{
    width: 0px;
  }

  &>button{
    width: 100%;
    border-radius: 7px;
    // box-shadow: 0px 0px 7px #d8dbde;
    &:hover:not(:disabled){
      filter: brightness(1.2);
    }
    &:disabled{
      filter: opacity(0.6);
    }
  }

  .loadings{
    height: 100% !important;
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0.3;
    border-radius: 5px;
    overflow: hidden;
    cursor: default;
    *{
      height: 100% !important;
    }
  }
  
  .view-detail {
    background: #a6a7af;
    color: rgb(67, 67, 105);
    border: none;
    padding: 6px 10px;
    margin: 2px;
  }

  .pay-now {
    background: #5d72f5;
    color: white;
    border: none;
    padding: 6px 10px;
    margin: 2px;
  }
  .change-payment {
    background: #4c81a0;
    color: white;
    border: none;
    padding: 6px 10px;
    margin: 2px;
  }
}
