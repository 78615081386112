.settings-access-management-page{

    .li-button{
        margin-left: 10px;
    }

    .stats-container{
        .con-display{
            background: #f7f7f7;
        }

        >.content{
            // background: red;
            display: flex;
            flex-direction: row;

            >.ini-content{
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                >.message{
                    text-align: center;
                    font-size: 16px;
                    font-weight: 600;
                    color: #a2a2a2;
                }
            }

            >.left{
                width: 220px;
                display: flex;
                flex-direction: column;
                margin-top: 20px;

                .l-title{
                    font-size: 14px;
                    color: gray;
                    margin-left: 15px;
                }

                .group-links{
                    overflow-y: auto;
                    flex: 1;
                    padding-left: 18px;
                    list-style-type: none;
                    font-size: 14px;

                    >.g-parent, >.g-link{
                      padding: 8px 6px;
                      display: flex;
                      align-items: center;

                      .icon{
                          margin-right: 6px;
                      }
                      *{
                          fill: gray
                      }
                    }

                    >.g-parent{
                      color: gray;
                    }

                    >.g-link{
                        cursor: pointer;
                        color: #2679e3;
                        border-radius: 6px 0px 0px 6px;
                        transition: background-color .5s;

                        &.sub{
                          padding-left: 22px;
                        }

                        &:hover{
                            background: #f4f4f4;
                        }

                    }
                }
            }

            .list-content{
                width: 100%;
            }

            >.cont{
                flex: 1;
                padding: 14px;
                overflow-y: auto;

                >.accordion{
                    margin: 16px 10px;
                    box-shadow: 9px 1px 17px 3px rgba(89, 116, 230, 0.07);
                    .group-header {
                        background: white;
                        padding: 6px 10px;
                        border-radius: 4px;
                        // cursor: pointer;
                        position: relative;
                        display: flex;
                        align-items: center;


                        &.locked{
                            background: #f7f7f7;
                            border: 1px solid #e1e1e1;
                            .icon{
                                margin-right: 10px;
                                *{
                                    fill: #666666;
                                }
                            }

                            .group-name{
                                font-size: 16px;
                                color: #666666;
                                margin-bottom: 0px;
                                flex: 1;
                            }

                        }



                        .icon{
                            margin-right: 10px;
                            *{
                                fill: #3582e5;
                            }
                        }

                        .group-name{
                            font-size: 16px;
                            color: #257ae3;
                            margin-bottom: 0px;
                            flex: 1;
                        }

                        .drop-hint{
                            visibility: hidden;
                            opacity: 0;
                            transition: visibility 0s, opacity 1s linear;
                            position: absolute;
                            top: 15px;
                            left: 50%;
                            align-items: center;
                            padding-left: 20px;

                            *{
                                fill: #989797;
                                color: #989797;
                                font-size: 12px;
                            }
                            >p{
                                margin: 0px;
                                margin-right: 10px;
                            }
                        }

                        // .status :hover{
                        //     .drop-hint{

                        //         visibility: visible;
                        //         opacity: 1;
                        //         display: flex;

                        //     }
                        //     background: red;
                        // }

                        &:hover{
                            .drop-hint{
                                visibility: visible;
                                opacity: 1;
                                display: flex;
                            }
                        }

                        .status {
                            background: transparent;
                            border: none;
                            outline: none;
                            border-radius: 25%;
                            // background: #f5f3f3;
                            width: 40px;
                            height: 40px;
                            transition: background-color .1s ease;

                            *{
                                // transition: fill .1s ease;
                                fill: #a3a3a3;
                            }

                            &:hover{
                                *{
                                    fill: #0a9ff7;
                                }
                                background: #f5f3f3;
                            }

                            &.unlocked{
                                *{
                                    fill: #0a9ff7;
                                }

                                &:hover{
                                    *{
                                        fill: #a3a3a3;
                                    }
                                }
                            }
                        }
                    }

                    // .operations-list {
                    //     background: #f8fbff;
                    //     padding: 16px;

                    //     // :not(:last-child) .line{
                    //     //     border-bottom: 1px dashed rgba(51, 112, 247, 0.33);
                    //     // }
                    //     >:not(:last-child){
                    //         // .operation-item{
                    //             border-bottom: 1px solid #eeeeee;
                    //         // }
                    //     }


                    // }


                }
            }
        }
    }


}
