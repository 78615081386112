
// .columns-dropdown,.models-table-wrapper{
//     margin-bottom:20px;
// }
// a:hover{
//     text-decoration:none
// }
// a.active{
//     font-weight:700
// }
// pre{
//     text-align:left;
//     white-space:pre-line
// }
// .table-footer{
//     border-top: 1px solid #e6e2e287;
//     padding: 13px 0px 0px 0px;
// }
// .btn-default{
//     background-image:none!important
// }
// .table-column-options.table>tbody>tr>td{
//     border-top-width:0
// }
// .navbar-text.gh{
//     margin-top:13px!important;
//     margin-bottom:11px!important
// }





.dropdown-menu{
    @include dropdown-bg;
}

.main-column{
    font-weight: 600;
}



.has-main-table {

    .table{

        border-collapse: separate;
        border-spacing: 0em 0px;
        // background: $mini-menu-background;
        padding: 0px 0px 1px 0px;
        border-radius: 10px;
        // @include primary-gradient-bg-diagonal;
        // background: linear-gradient(135deg, #7e9cf0 0%, #4973e9 100%);
        // background-image: linear-gradient(135deg, #8298f1 0%, #6a7dd0 100%);
        background-image: linear-gradient(135deg, #467F8C 0%, #467F8C 100%);
        // box-shadow: 0px 0px 14px 0px rgba(154, 154, 154, 0.51);

        // th{
        //   background-color: #6cade0 !important;
        // }

      > thead > tr > th {
            border-bottom: none;
            font-size: 12px;
            border-width: 0px 0px 1px 0px;
            border-style: solid;
            /* border-color: transparent; */
            padding: 13px 11px;
            color: white;
            vertical-align: top;
            //text-transform: capitalize;
        }

      //> th:first-letter {
      //  text-transform: capitalize;
      //}

        > tbody{
            background: white;
        }

        > tbody > tr:first-child > td{
            border-top: none !important;
        }
        > tbody > tr > td, .table > tfoot > tr > td {
            line-height: 1.42857143;
            vertical-align: top;
            border-top: 1px solid #eff0f1;
            padding: 11px;
            color: #383838;
            font-size: 12px;
            vertical-align: middle;
        }

        > tbody > tr, .table > tfoot > tr{
            padding: 10px;
            background: white;

            &.selected-row{
                // background: rgb(225, 236, 255);
                background: #eff3fe;
                td{
                    // color: $primary-color;
                    color: #098aff;
                }

            }
        }

        &:not(.on-edit) {
            > tbody > tr:hover, .table > tfoot > tr:hover{
                background:rgba(105, 104, 104, 0.08);
                cursor: pointer;
            }
        }


    }

    .table-footer {
        // border-top: 1px solid #e6e2e287;
        border: none;
        padding: 13px 0px 0px 0px;

        .table-summary {
            font-weight: 600;
        }


        .changePageSize{
            outline: none;
            border-radius: 8px;
            background: white;
            color: #828080;
            font-weight: 600;
            border: none;
            box-shadow: -1px 1px 12px rgba(85, 132, 191, 0.2);
            // width: 47px;
            cursor: pointer;

            &:hover{
                background: rgba(92, 114, 246, 0.071);
            }

        }
    }

    .btn-toolbar{
        .btn{
            @include app-button;
            @include outline-button;
            @include primary-outline;
            border-color: #dededf;
            // background: red;
        }
    }

    .btn-default:active:hover, .btn-default.active:hover, .open > .dropdown-toggle.btn-default:hover, .btn-default:active:focus, .btn-default.active:focus, .open > .dropdown-toggle.btn-default:focus, .btn-default:active.focus, .btn-default.active.focus, .open > .dropdown-toggle.btn-default.focus
     {
        color: inherit;
        background-color: inherit;
        border-color: inherit;
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        text-shadow: inherit;
    }

    .btn-group.open .dropdown-toggle {
        box-shadow: inherit;
        text-shadow: inherit;
    }

    .btn-default:active, .btn-default.active, .open > .dropdown-toggle.btn-default {
        color: inherit;
        background-color: inherit;
        border-color: inherit;
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        text-shadow: inherit;
    }

    .btn-group > .btn:hover, .btn-group-vertical > .btn:hover, .btn-group > .btn:focus, .btn-group-vertical > .btn:focus, .btn-group > .btn:active, .btn-group-vertical > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn.active {
        text-shadow: none;
    }

    .pull-right.columns-dropdown{
        margin: 0px;padding: 0px!important;
    }

    .dropdown-toggle{
        border: none;
        box-shadow: none;
        background: transparent;
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
    }



}
