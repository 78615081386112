

.user-profile-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px;
}

.user-pic-wrapper{
    margin-top: 27px;
    width: 85px;
    height: 85px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 0px 20px 7px rgba(233, 237, 242, 0.26);

    .user-pic{
        display: block;
        width: 100%;
        height: auto;
        background: white;
    }
    
}
.up-name, .up-role{ color:white; margin: 0px;}
.up-name{
    font-size: 20px;
    margin-top: 10px;
}
.up-role{
    font-size: 14px;
    color: #bbe2fb;
    margin-top: 0px;
}


.user-profile-content{
    // padding: 6px;
    // margin-bottom: 10px;

    .up-facility {
        font-size: 15px !important;
        font-weight: bold;
        color: dimgrey;
    }
    
    .up-facility-label {
        font-weight: 600;
        color: rgba(156, 155, 155, 0.933);
        margin: 0px;
        font-size: 12px !important;
    }
}
