
// .toolbar{
//     width: 100%;
//     background: white;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     font-family: Helvetica, sans-serif;
//     margin-right: $quick-action-button-width;
//     flex-wrap: wrap;

// }

.toolbar-item{
    position: relative;
    cursor: default;
    outline: none;
    .ti-badge{
        position: absolute;
        top: 0px;
        right: -4px;
        font-size: 10px;
        background-color: #f0f4ff !important;
        color: #006daf !important;
    }
    .t-content{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 16px;
        // margin-left: 20px;
        flex-wrap: nowrap;
        border-top: 2px solid #75B2BF;
    }
    .attract{
        animation: attract .8s infinite alternate ease-in-out;
    }
    .loader {
        position: absolute;
        bottom: 0;
        height: 100%;
        filter: opacity(0.2);

        * {
            height: 100% !important;
        }
    }

    &.disabled, &.waiting{
        *{
            cursor: default;
        }
    }




}

.tb-menu{
  // padding: 14px;

  box-shadow: 0px 5px 11px 1px rgba(94, 124, 250, 0.369);

  .tb-menu-tem {
      padding: 14px;
      cursor: pointer;
      font-size: 14px;
      color: #565656;

      &:hover{
        background: #efefef;
      }

      &:not(:first-child){
        border-top: 1px solid #f9f9f9;
      }
  }
}

@keyframes attract{
    // from, 49% { transform-origin: -50% 100%; }
    // 50%, 75%, to { transform-origin: 150% 100%; }
    25% { transform: scale(1); }
    50% { transform: scale(1); }
    75% { transform: scale(1.04); }
    // from, 49% { transform-origin: -50% 100%; }
    // 50%, 75%, to { transform-origin: 150% 100%; }
    // 25% { transform: translate(0px, 0px) rotate(0deg); }
    // 50% { transform: translate(0px, 0px) rotate(0deg); }
    // 75% { transform: translate(1px, 1px) rotate(0deg); }

    // from, 49% { transform-origin: -50% 100%; }
    // 50%, 75%, to { transform-origin: 150% 100%; }
    // 25% { transform: rotate(-1deg); }
    // 50% { transform: rotate(0deg); }
    // 75% { transform: rotate(1deg); }
    // 0% { -webkit-transform: translate(2px, 1px) rotate(0deg); }
	// 10% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); }
	// 20% { -webkit-transform: translate(-3px, 0px) rotate(1deg); }
	// 30% { -webkit-transform: translate(0px, 2px) rotate(0deg); }
	// 40% { -webkit-transform: translate(1px, -1px) rotate(1deg); }
	// 50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); }
	// 60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); }
	// 70% { -webkit-transform: translate(2px, 1px) rotate(-1deg); }
	// 80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); }
	// 90% { -webkit-transform: translate(2px, 2px) rotate(0deg); }
	// 100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); }
  }

.toolbar-item:not(.disabled):not(.waiting){
    cursor: pointer;
    &:hover{
        // background: rgba(246, 250, 255, 0.231);
        // background: rgba(233, 234, 235, 0.231);
        // box-shadow: 1px 1px 17px 1px #d932d730;
        // box-shadow: 1px 1px 17px 1px rgba(10, 159, 247, 0.29);
        background-color: #CEECF2;
    }
}

.toolbar-item.disabled  {
    *{
        fill: rgba(143, 139, 139, 0.97);
        color: rgba(143, 139, 139, 0.97);
    }
}
// .toolbar-item.primary:not(.disabled) g, .toolbar-item.primary:not(.disabled) path{
//     // fill:$primary-color;
//     fill:#e72cd3;
// }

.toolbar-item.primary:not(.disabled) {
    *{
    // fill:$primary-color;
    fill:#0a9ff7;
    }
}

.toolbar-item .icon{
    height: 20px;
    width: 20px;
}

.toolbar-item{
    &:not(.disabled){
        .text{
            // background: linear-gradient(135deg, #e72cd3 0%,rgba(89,97,249,1) 100%);
            // background: linear-gradient(135deg, #bf11ed 0%, #062b95 100%);
            // background: -webkit-linear-gradient(#0a9ff7, #0648d6);

            color: #2C4C59;
            
            // background: linear-gradient(135deg, #0a9ff7 0%, #0648d6 100%);
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
        }
    }
    .text{
        margin-left: 6px;
        font-size: 15px;
        // color: $primary-color;
        font-weight: 500;

    }
}
