.q-edit-item{

    .q-item-wrapper{
        height: 100%;
        margin: 6px;
    }
    .is-sub{
        margin-left: 20px;
    }

    &:not(.is-sub){
        padding: 10px 5px;
        &>.q-item-wrapper{
            display: flex;
            flex-direction: column;
            // border:1px solid red;
            background: white;
            border-radius: 4px;
            padding: 10px;
        }
    }
    label:hover{
        cursor: pointer;
    }
    .q-label {
        font-size: 11px;
        color: #667280;
        &:not(.is-sub){
            color: #394047;
            font-size: 12px;
            font-weight: 600;
        }
    }

    .main-row{
        display: flex;
        align-items: center;
    }
    .q-label{

    }
}
