.active-serve-list{
    display: flex;
    flex-direction: column;;
    padding: 15px;
    position: relative;
    padding-top: 0px;
    padding-bottom: 0px;
    

    &.margin-up{
        margin-top: -40px;
    }


    .as-toggle-wrapper{
    //     position: absolute;
    //     top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        // padding-bottom: 10px;
        // margin-top: 5px;
        .as-toggle{
            background: transparent;
            border: none;
            color: $primary-color;
            outline: none;

            &:hover{
                text-decoration: underline;
            }
        }
    
    }

    .as-list-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        overflow-x: auto;
        padding-top: 6px;
        padding-bottom: 6px;

        :not(:first-child) {
            margin-left: 20px;
        }
    }
    .as-grid-wrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        overflow-y: auto;
        // justify-content: center;
        // justify-items: left;

        &>*{
            margin-top: 20px;
            margin-left: 20px;
        }
    }
    
    
}

.active-serve {
    width: 260px;
    height: 110px;
    box-shadow: 3px 3px 13px 6px rgba(77, 123, 175, 0.22);;
    border-radius: 4px;
    padding: 16px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-image: linear-gradient(-45deg, #416683 10%, #8999c2 100%);
    filter: brightness(1.2);

    &:hover{
        filter: brightness(1.3)
    }

    .infos{
        margin-left: 16px;
        overflow: hidden;

        .p-name {
            font-size: 21px;
            color: #ffffff;
            margin: 0px;
            word-wrap: none;
            white-space: nowrap;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .v-purpose {
            font-size: 14px;
            color: #aacaff;
            margin: 4px 0px 0px 2px;
        }

        .duration {
            margin: 0px;
            margin-top: 7px;
            text-align: right;
            color: white;
        }


    }

    svg.icon > * {
        fill: #ececec;;
    }


}

.item-one{

}
