.row-options{
  display: flex;
  justify-content: flex-end;

  .btn-more{
    height: 20px;
    width: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    &:hover{
      background: rgb(221, 221, 221);
    }
    .more-icon{
      height: 17px;
      width: 14px;
    }
  }
  
}