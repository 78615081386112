.material-input-group{
    position:relative; 
    // margin-bottom:45px; 
    // background: rebeccapurple;
  }


  .material-input-group input {
    // font-size:18px;
    padding:10px 10px 5px 5px;
    display:block;
    border:none;
    border-bottom:1px solid #d4d2d2;
  }
  .material-input-group input:focus
  { outline:none; }
  
  /* LABEL ======================================= */
  .material-input-group label 				 {
    color:#999; 
    // font-size:18px;
    font-weight:normal;
    position:absolute;
    pointer-events:none;
    left:5px;
    top:10px;
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
  }
  
  /* active state */
  .material-input-group input:focus ~ label, .material-input-group input:valid ~ label{
    
    top: -15px;
    font-size: 9px;
    color: $primary-color;
    font-weight: 600;
  }
  
  /* BOTTOM BARS ================================= */
  .material-input-group .bar 	{ 
      position:relative; display:block; width:100%; }

  .material-input-group .bar:before, .material-input-group .bar:after 	{
    content:'';
    height:2px; 
    width:0;
    bottom:1px; 
    position:absolute;
    background:$primary-color; 
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
  }
  .material-input-group .bar:before {
    left:50%;
  }
  .material-input-group .bar:after {
    right:50%; 
  }
  
  /* active state */
  .material-input-group input:focus ~ .bar:before, .material-input-group input:focus ~ .bar:after {
    width:50%;
  }
  
  /* HIGHLIGHTER ================================== */
  .material-input-group .highlight {
    position:absolute;
    height:60%; 
    width:100px; 
    top:25%; 
    left:0;
    pointer-events:none;
    opacity:0.5;
  }
  
  /* active state */
  .material-input-group input:focus ~ .highlight {
    -webkit-animation:inputHighlighter 0.3s ease;
    -moz-animation:inputHighlighter 0.3s ease;
    animation:inputHighlighter 0.3s ease;
  }