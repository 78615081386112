.top-most{
  z-index: 999 !important;
}
.alert-dialog-panel{
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh !important;
  width: 100vw !important;
  background: transparent;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;

  .positioner{
    height: auto;
    min-width: 240px;
    max-width: 80%;
    max-height: 80%;
    position: absolute;
    top: 0;
    margin-top: 80px;
    z-index: 2;
    display: flex;
    flex-direction: column;

    // height: auto;
    // min-width: 240px;
    // max-width: 80%;
    // max-height: 80%;
    // position: absolute;
    // top: 0;
    // margin-top: 80px;
    background: white;
    // box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px transparent;
    box-shadow: 0px 3px 20px 6px #b6d1ec7d;
    border-radius: 4px;

    .dark-header, .dark-footer{
      background: #f7f8fb;
    }
    .warning-header{
      background: #ffffc5;
    }
    .dark-header{
      border-radius: 4px;
    }
    .dark-footer{
      border-radius: 4px;
    }
    .alert-header{
      padding: 17px;
      padding-bottom: 0px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .alert-content{
        flex: 1;

        &.c-padding{
          padding: 30px 20px;
        }

    }
 

    .alert-footer{
      flex-shrink: 0;

      .waiting-progress{
        position: absolute;
        bottom: 0;
        opacity: 0.9;
      }

      .flex{
        display: flex;
        align-items: center;

        .footer-info{
          color: #8c8c8c;
          font-size: 13px;
        }
      }
    }

    &.prompt-dialog{
      min-width: 386px !important;
    }
  }

  // .alert-container{
  //   background: white;
  //   z-index: 2;
  //   border-radius: 4px;
  //   position: relative;
  //   box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0);
  // }

  .alert-bottom-blend{
    height: 78px;
    width: 600px;
  }
}

.alert-unfocus{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: rgba(17, 76, 176, 0.33);
  // background: rgba(65, 97, 152, 0.48);
  background: $unfocus-bg;
  z-index: 1;
}

  .show-alert{
    max-height: 100%;
    opacity: 1;
    // transition:visibility 0.3s linear,opacity 0.3s linear, max-height 0.3s linear;
    .alert-unfocus{
      visibility: visible;
    }
  }

  .max-z-index{
    z-index: 999;
  }

  .hide-alert{
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    // transition:visibility 0.3s linear,opacity 0.3s linear, max-height 0.3s linear;
    .alert-unfocus{
      visibility: hidden;
      // transition:visibility 0.3s linear;
    }
  }

.alert-unfocus{
  background:$unfocus-bg;
}

.alert-title-actions-wrapper{
  position: relative;

  .alert-actions-trigger{
    font-size: 14px;
    padding: 2px;
    
  }

  #alert-actions{
    position: absolute;
    right: 0;
    top:0;
  }
}


.alert-title{
  // position: absolute;
    font-size: 16px;
    text-align: center;
    // padding: 17px 17px 0px 17px;
    font-size: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    svg, path{
      fill: #747474;
    }

  p{
    margin-left: 6px;
    color: #525457;
    font-weight: 500;
    text-transform: capitalize;
  }
}

.message{
  font-size: 14px;
  text-align: center;
  margin: 8px;
  color: #888a8c;
}


.alert-content{
  padding: 10px 20px;
  font-weight: 500;
  overflow-x: auto;

  &.stats-alert-content{
    padding: 0px 0px;
  }

  .a-margin-bottom{
    margin-bottom: 30px;
  }

  .prompt-input{
    width: 100%;
    outline: none;
    border: 1px solid hsla(211, 96%, 80%, 0.49);
    border-radius: 4px;
    font-size: 15px;
    padding: 5px;
    // box-shadow: 1px 1px 11px #d2d0d04d;

    &.text-area{
      max-width: 100%;
      min-height: 60px;
    }
  }
}




.confirm-buttons-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px 20px;

  // &.a-margin-top{
  //   margin-top: 37px;
  // }

  .confirm-button{
    border: none;
    border-radius: 5px;
    padding: 8px 23px;
    font-size: 17px;
    font-weight: 400;
    outline: none;
    background: transparent;

    &.yes{
      color: $green-color;
    }

    &.no{
      color: $red-color;
    }

    &:hover:not(:disabled) {
      background: #f4f4f4;
    }

    &:disabled {
      background: white;
      color: gray !important;
    }
  }
}


button.alert-close {
  position: absolute;
  right: -26px;
  top: -26px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  background: white;
  border: none;
  box-shadow: 0px 2px 12px 0px #b5cbfd;
  color: gray;
  outline: none;

  &:hover{
    background: #fdc9d4;
    color: red;
  }
}
