
// @mixin soft-white-input{
//     color: $primary-color;
//     border-color: $primary-color;
//     &:hover{
//         background: $primary-color;
//         color: white;
//     }
// }

.soft-input {
    &input, input, .md-input {
        outline: none;
        border: 1px solid #f9f5f5 !important;
        padding-left: 6px;
        border-radius: 3px;
        font-size: 14px;
        height: 30px;
        box-shadow: 2px 1px 9px 1px rgba(129, 154, 189, 0.18);
    }
    label{
      margin-left: 6px;
    }
}

.big-input, .big-input input{
  font-size: 38px !important;
  height: 90px;
}
