
.tab-control{
    width: 100%;
    border-bottom: 1px solid #75B2BF;
    margin: 10px 0px 8px;
    padding-left: 0;
    list-style: none;
    margin-top: 0px;
    text-align: center;
    li{
        // float: left;
        margin-bottom: -1px;
        display: inline-block;
        &.active{
            color: #477f8c;
            cursor: default;
            background-color: #fff;
            border: 1px solid #75B2BF;
            border-bottom-color: transparent;
            border-radius: 10px 10px 0 0;

            a{
                color: #477f8c;
                cursor: default;
                // background-color: #fff;
                border-bottom-color: transparent;
            }
        }

        a{
            margin-right: 2px;
            line-height: 1.42857143;
            border: 1px solid transparent;
            // border-radius: 4px 4px 0 0;
            display: block;
            padding: 4px 25px;
            cursor: pointer;
            text-decoration: none !important;
            font-size: 14px;
            font-weight: 600;
        }

        .badge-text{
            top: -1px;
            right: 0;
            color: white;
            background: #5182ff;
            border-radius: 10px;
            padding: 3px 7px;
            margin-left: 3px;

        }
    }


}
