
.full-calendar{

}

.full-calendar .fc-center h2{
    color: $primary-color;
    font-size: 16px;
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.full-calendar .fc-button{
    @include gray-button;
    text-shadow: 0px 0px transparent;
    box-shadow: 0px 0px transparent;
    border: none;
    height: 28px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 12px;
    outline: none;
}

.full-calendar .fc-state-disabled:hover{
    background: $gray-button-background !important;
    color: $gray-button-forecolor !important;
}

.full-calendar .fc-corner-right{
    border-top-right-radius: $edge-radius;
    border-bottom-right-radius: $edge-radius;
}

.full-calendar .fc-corner-left{
    border-top-left-radius: $edge-radius;
    border-bottom-left-radius: $edge-radius;
}

.full-calendar .fc-button:hover{
    background: $primary-light-color;
    color: white;
}


.full-calendar .fc-state-active{
    background: $primary-color;
    color: white;
}

.full-calendar .fc-state-active:hover{
    background: $primary-color;
    color: white;
}

.full-calendar .fc-day-header{
    padding: 8px;
    border: none;
    color: white;
}

.full-calendar .fc-head-container{
    border-radius: 6px 6px 0px 0px;
    background: $primary-color;
    border: none;
}

.full-calendar td:first-of-type{
    // border-color: $primary-color;
    // border-top-width: 0px;
    border-left-color: $primary-color !important;
    // border-right-color: $primary-color !important;
    border-bottom-color: $primary-color !important;
}

.full-calendar .fc-body{
    // border-color: $primary-color;
    // border-top-width: 0px;
    border-top-color: $primary-color !important;
    border-top-style: solid;
}

.full-calendar td:last-of-type{
    // border-color: $primary-color;
    // border-top-width: 0px;
    // border-left-color: $primary-color !important;
    border-right-color: $primary-color !important;
    // border-bottom-color: $primary-color !important;
}

.full-calendar .fc-widget-header{
    border: none;
}

.fc-event, .fc-event-dot {
    background-color: #FFA400;
}
.fc-event {
    font-size: 11px;
    padding: 6px;
    border: 1px solid #A76B00;
}

