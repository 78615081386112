
.big-input-wrapper{
    width: 216px;
}

.input-icon-container{
    display: flex;
    flex-direction: row;
    // justify-content: center;
    // align-items: center;
    // width: 100%;
    // background: red;
    // overflow-x: hidden;

}
.input-icon-container .input-icon-content .ember-view{
    width: 100% !important;
    // background: violet;display: flex
}
.input-icon-container .input-icon-content{
    // width: 100% !important;
    // background: yellow;
    flex-grow: 1;
    flex-shrink: 1;
}
.input-icon-container .input-icon{
    font-size: 18px;
    margin: 18px 10px 0px 10px;
    flex-grow: 0;
    flex-shrink: 0;
    // float: left;
}
.input-icon-container .md-select{
    width: 100%;
}

.choose-image{
    display: flex;
    flex-direction: row;
    align-items: center;


}

.choose-image-image{
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
    // min-height: 80px;
    border-radius: 10%;
    border-width: 1px;
    border-color: rgba(100, 100, 100, 0.562);
    border-style: solid;

}

.lazy-image-placeholder {
    position: absolute;
    top: calc(#{50%} - 20px);
    left: calc(#{50%} - 20px);;
}

.choose-image-content{
    // width: 100%;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    .label{
      font-size: 12px;
      color: gray;
    }
}

.custom-file-upload {
    // border: 1px solid #ccc;
    display: inline-block;
    // padding: 6px 12px;
    cursor: pointer;
}

.custom-file-upload input[type="file"] {
    display: none;
}

.searchbox {
    border-radius: 500rem;
    width: 300px;
    margin: 0;
    outline: none;
    -webkit-appearance: none;
    text-shadow: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2142em;
    padding: .67861429em 1em;
    font-size: 12px;
    background: #FFF;
    border: 1px solid rgba(34,36,38,.15);
    color: rgba(0,0,0,.87);
    box-shadow: 0 0 0 0 transparent inset;
    -webkit-transition: background-color .1s ease,color .1s ease,box-shadow .1s ease,border-color .1s ease;
    transition: background-color .1s ease,color .1s ease,box-shadow .1s ease,border-color .1s ease;
}


.start-cont-dropdown{
    margin-top: 14px;
    font-size: 12px;
    width: 230px;
}

.drop-down-content-container{
    position: absolute;
    z-index: 1;
    left: -130px;
    top: 9px;
    min-height: 10px;
    min-width: 10px;
    width: 390px;
    @include dropdown-bg;
}

.drop-down-content-container > .triangle{
    position: absolute;
    top: -8px;
    left: 134px;
    height: 15px;
    width: 15px;
    border-radius: 4px 0px 0px 0px;
    transform: rotate(45deg);
    background: inherit;
    z-index: -100;
    border: inherit;
    box-shadow: inherit;
}

.drop-down-content-container > .dd-container{
    background: inherit;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    overflow: hidden;
    display: flex;
    flex-direction: row;
}

.date-filter-chooser{
    background: inherit;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    overflow: hidden;
    display: flex;
    flex-direction: row;
}

.date-filter-chooser .content{
    padding: 16px;
    flex: 1;
}

.date-filter-chooser .menu{
    background: $mini-menu-background;
    // border-bottom-left-radius: inherit;
    // border-bottom-right-radius: inherit;
    // border-top-left-radius: inherit;
    // border-top-right-radius: inherit;
}

.month-chooser .month{
    width:70px;
    padding:8px 6px;;
    margin:2px;
    background:$gray-button-background;
    color: $gray-button-forecolor;
    outline: none;
    border: none;

    // .seleted{
    //     background: $primary-color;
    //     color:white;
    // }
    &:hover:not(:disabled) {
        background: $primary-light-color2;
        color: white;
        // transform: translateX(-50px);
        // i {
        //   margin-right: 10px;
        // }
    }

}
.month-chooser .month.selected:not(:disabled) {
    background: $primary-light-color;
    color:white;
}



.rounded-tools-group{
    padding: 6px 0px;
    display: flex;
    flex-direction: row;
    font-size: 12px;
}
.rounded-tools-group > :first-child{
    // border-radius:  500rem 0px 0px  500rem;
    border-top-left-radius: 500rem !important;
    border-bottom-left-radius: 500rem !important;
}

.rounded-tools-group > :first-child:not(:last-child){
    // border-radius:  500rem 0px 0px  500rem;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right-width: 0px !important;
}

.rounded-tools-group>*{
    @include outline-button;
    border-radius: 0px;
    // border: none;
    color: $primary-color;
    border-color:rgba(34, 36, 38, 0.15);
    align-items: center;
    display: flex;
    padding: 6px 16px;
    border: none;
    border-color: transparent;
    box-shadow: 0px -1px 8px #b5b1b166;
}

.rounded-tools-group >*.active{
    background: $primary-color !important;
    color: white !important;
}

.rounded-tools-group >*:not(.searchbox):hover{
    background: $primary-color !important;
    color: white !important;
}

.rounded-tools-group>.ember-basic-dropdown-content-wormhole-origin{
    display: none;
}


.rounded-tools-group > :last-child, .rounded-tools-group > .ember-basic-dropdown-trigger:nth-last-child(2){
    // border-radius:  0px 500rem 500rem  0px;
    border-top-right-radius: 500rem !important;
    border-bottom-right-radius: 500rem !important;
}


.rounded-tools-group > :not(:first-child):not(:last-child):not(.ember-basic-dropdown-trigger){ //:not(.ember-basic-dropdown-trigger)
    border-radius:  0px 0px 0px  0px !important;
    border-right-width: 0px !important;
}

.badge-button{
    position: relative;

    .badge-text{
        position: absolute;
        top: -11px;
        right: 0;
        color: white;
        background: #3e72cb;
        border-radius: 10px;
        padding: 3px 7px;

    }
}



.full-width-text-area{
    max-width: 100%;
    min-width: 100%;
    margin: 0px;
    height: 96px;
    width: 526px;

    border: 1px solid #c6bdbd;
    border-radius: 4px;
    font-size: 15px;
    padding: 5px;
}

.file-drop-zone{
    padding: 13px 25px;
    border: dashed 1.7px #c7d0e4;
    border-radius: 4px;
    background: #f7faff;
    height: 100%;
    width: 100%;

    &.mini{
        height: 94px;
        width: 168px;
    }

    &.on-valid-drag{
        border-color: #3e72cb;
        background: #e9f3ff;
    }
    &.on-invalid-drag{
        border-color: #cb3e3e;
        background: #ffe9e9;
    }

    .dd-info{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .icon{
          height: 50px;
          width: 50px;

          *{
            fill:gray;
          }
        }
        p{
          font-size: 14px;
        }

        .file-upload{
          margin-top: 20px;

          #upload-image{
            color: #ffffff;
            padding: 10px;
            outline: none;
            cursor: pointer;
            background: #687893;
            border-radius: 4px;
            font-size: 13px;

              &:hover{
                  background: #3e72cb;
              }
          }
        }

    }

    .small-fd{

    }







}

.note-text{
    border: solid 1px #e1e1e1;
    padding: 6px;
    border-radius: 4px;
    font-size: 16px;
    color: gray;
}

.app-tooltip  {
    position: relative;
    display: inline-block;
    // border-bottom: 1px dotted black; /* If you want dots under the hoverable text */

    .tooltiptext {
        visibility: hidden;
        opacity: 0;
        min-width: 120px;
        background-color: #529eeb;
        color: #ffffff;
        text-align: center;
        font-size: 12px;
        padding: 5px 1px;
       
        /* Position the tooltip text - see examples below! */
        position: absolute;
        top: 115%;
        left: 50%;
        margin-left: -60px;
        z-index: 999;
        transition: opacity 1s;

        &::after {
            content: " ";
            position: absolute;
            bottom: 100%;  /* At the top of the tooltip */
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent #529eeb transparent;
        }
    }

    &:not(.disabled):hover .tooltiptext {
        visibility: visible;
        opacity: 1;
    }
}

  
