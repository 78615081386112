


.single-image-wrapper{
  display: flex;
  justify-items: center;
  align-items: center;
  margin: 46px;

  .single-image {
      width: 200px;
      height: 200px;
      background: whitesmoke;
      border-radius: 10px;
      padding: 9px;
      /* border: 1px solid red; */
  }

  .single-ext {
    color: #536a97;
    font-weight: 600;
    font-size: 20px;
  }
}
