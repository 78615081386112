.no-data-message{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    svg *{
        fill: #a2a2a2;
    }
    >.message{
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        color: #a2a2a2;
        margin-left: 20px;

        &.small{
            font-size: 12px;
        }
    }

    
}