
.schedule-chip
{
	.md-chips {
		md-chip{
			height:auto;
			line-height:initial;

			.schedule-cell-doc{
				font-size:12px;
			}

			.md-chip-remove{
				height: 14px;
				min-height: 0px;
				min-width: 0px;
				font-size: 13px;
				line-height: 14px;

				top: 38% !important;

				md-icon{
					font-size: 12px !important;
				}

			}

		}
    .md-chip-input-container:not(:first-child){
      margin: 0;
    }
    .md-chip-input-container{
      margin: 0;
      display: block;
      line-height: 32px;
      padding: 0;
      float: left;
    }

	}


}
