.breadcrumb{
    // background-color: #d2d5f3;
    border-radius: 0px;
    a{
        color: #3c67e8;
    }

    &> li {
        color: gray;
    }
}