

md-input-container{
    // background: rebeccapurple;
    margin-top: 8px;
    margin-bottom: 8px;
    // min-width: 300px;
}

.md-show-clear-button button{
    top:0px !important;
    // background: red !important;
}

.md-checkbox.md-default-theme.md-checked .md-icon, md-checkbox.md-checked .md-icon {
    background-color: $accent-color;
}

element.style {
}

md-checkbox {
    margin-bottom: 0px;
}

md-checkbox .md-icon {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: 240ms;
    transition: 240ms;
    position: absolute;
    top: 0;
    left: 0;
    width: 19px;
    height: 19px;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
}
md-switch {
    margin: 0px 0;
}

.paper-checkbox-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}

// .md-virtual-repeat-container.md-autocomplete-suggestions-container {
//
//     z-index: 10;
// }
