.consent-group{
    background: white;
    padding: 10px;
    &:not(:first-child){
        margin-top: 3px;
    }

    .cg-head{
        display: flex;

        .cg-info {
            flex: 1;
            .cg-title {
                font-weight: 600;
                font-size: 15px;
            }

            .cg-description {
                font-size: 12px;
                margin-top: 4px;
            }
        }

        .cg-tab{
            cursor: pointer;
            .cgt-title{
                font-size: 12px;
            }
            .cgt-info{
                font-size: 12px;
                margin-top: 4px;
            }

            &:not(.cg-tab-select):hover{
                background: $darker-content-bg;
            }
        }
        

        &>*{
            padding: 10px;
        }
        .cg-tab-select{
            background: $dark-content-bg;
        }
    }
    .cg-content{
        background: $dark-content-bg;
        padding: 12px;

        button{
            margin-bottom: 0px;
        }

        .exception-info{
            font-size: 12px;
        }

        .exception-button-wrapper{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

    }
}