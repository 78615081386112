//Colors
.primary-color{
    color: $primary-color !important;
}

.green-color{
    color: $green-color !important;
}

.red-color{
    color: $red-color !important;
}

.gray-color{
    color: #757677;
}

.dark-color{
    color: #757677;
}

.warn-color{
    color: #f1993d !important;
}

.warning-info{
    color: #f1993d !important;
    // font-weight: 600;
}

//sizes
.full-width{
    width: 100%;
}
.full-height{
    height: 100%;
}
.space6{
    width: 6px;
}

.ui.floating.label{
    z-index: 1 !important;
}

//more style

.z-index-0{
    z-index: 0;
}

.display-bock{
    display: block !important;
}

.flex-shrink-0{
    flex-shrink: 0;
}

.flex-row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-shrink: 0;
    // flex-wrap: wrap;
}
.flex-row-wrap{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    // flex-shrink: 0;
}

.flex-1{
    flex: 1;
}

.flex-column{
    display: flex;
    flex-direction: column !important;
}

.justify-content-center{
    justify-content: center;
}
.justify-items-center{
    justify-items: center;
}


.relative-position{
    position: relative;
}

.absolute-position{
    position: absolute;
}

.overflow-y{
    overflow-y: auto;
}
.overflow-x{
    overflow-y: auto;
}

.ws-padding-top{
    padding-top:10px;
}

.border-radius{
    border-radius: 5px;
}

.container-padding{
    padding: 8px;
}
.remove-left-padding{
    padding-left: 0px;
}

.min-20-margin-top{
    margin-top: -20px;
}

.margin-top-20{
    margin-top: 20px;
}

.margin-top-6{
    margin-top: 6px;
}

.margin-bottom-6{
    margin-bottom: 6px;
}
.margin-bottom-20{
    margin-bottom: 20px;
}

.margin-left-6{
    margin-left: 6px;
}

.margin-left-20{
    margin-left: 20px;
}

.margin-right-6{
    margin-right: 6px;
}

.height-20{
    height: 20px;
}


.margin-bottom-20{
    margin-bottom: 20px;
}

.margin-bottom-14{
    margin-bottom: 14px;
}
.align-self-center{
    align-self: center;
}

.align-items-center{
    align-items: center;
}
.reduce-bottom-by-title-bottom{
    margin-bottom: -46px;
}

.padding-left-0{
    padding-left:0px;
}

.padding-right-0{
    padding-right:0px;
}

.float-right{
    float: right;
}

.float-left{
    float: left;
}

.margin-0{
    margin: 0;
}

.ml-80{
    margin-left: 80px;
}

.input-font{
    font-size: 14px;
}

.flash {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  30% {
    opacity: 0;
  }
}

.jrPage{
  transform: scale(1.5) !important;
}

.tabfirst{
  margin-left: 25px;
}

.scroll-hide{
    transition: transform 1s ease-in-out;
}

.scroll-hide.hidden{
    transform: translateY(100%);
}

.flex-align-center{
  display: flex;
  align-items: center;
}

// .ins-sort-item::before {
//     content: '=';
//     color: #cccccc;
//     margin-right: 0px;
// }
.ins-sort-item {
    .index{
        background: #d19801;
        color: white;
        padding: 1px 4px;
        font-size: 12px;
    }
    background: #f1f1f1;
    padding: 10px;
    margin-right: 8px;
}

.overflow-auto{
    overflow: auto;
}

.overflow-y-auto{
    overflow-y: auto;
}

.overflow-x-hidden{
    overflow-x: hidden;
}

.list-item-mini-height{
    height: 35px !important;
}

.alert-actions-trigger{
    outline: none;
    color: $primary-color;
    background: $dark-content-bg;
    // color: white;
    // border: 1px solid;
    padding: 3px 6px;
    border-radius: 2px;
}
.dropdown-list-content{
    display: flex;
    flex-direction: column;
    @include dropdown-bg;
    overflow: hidden;

    &>button{
        border: none;
        outline: none;
        text-align: left;
        padding: 6px 10px;
        background: white;
        font-size: 12px;
        font-weight: 600;
        color: $dark-text;

        &:hover{
            background: $dark-content-bg;
        }

        &:not(:first-child){
            border-top: 1px solid rgb(248, 248, 248);
        }
    }
}