.top-bar-button-container {
    margin-right: 10px;
    .tb-button-wrapper{
        width: 28px;
        height: 28px;
    }
    .top-bar-button{
        
        outline: none;
        cursor:pointer;
        font-weight: 100;
    
        // &:hover{
        //     background: rgba(255, 255, 255, 0.75);
        //     border-radius: 50%;
        // }
    
        // &:not(.disabled)
        // {
        //      *{
        //         fill:#5d72f5;
        //      }
        // }
    }

    .tb-tool-tip{
        z-index: 10;
        left: 13%;
        position:absolute;
        margin-top:10px;
        background: white;
        border-radius: 5px 5px 5px 5px;
        @include dropdown-bg;
    
        .tt-content{
            padding: 6px;
            background: white;
            white-space: nowrap;
            border-radius: inherit;
        
            p{
                font-size: 12px;
            }
        }
    }

    .tooltip-triangle{
        position: absolute;
        top: -6px; 
        left: 3px;
        height: 15px;
        width: 15px;
        transform: rotate(45deg);
        background: inherit;
        z-index: -1;
        border: inherit;
        -webkit-box-shadow: inherit;
        box-shadow: inherit;
    }



}