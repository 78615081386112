.app-select{


}

.ember-power-select-options:not(.classic){

  .ember-power-select-option{
    font-size: 14px;
    padding: 8px;
    // background: red;
  }
}
.ember-power-select-multiple-trigger{
  height: auto !important;
  padding-bottom: 6px !important;
}
.ember-power-select-multiple-options{
  .ember-power-select-multiple-option {
      border: none !important;
      border-radius: 50px !important;
      padding: 5px 10px !important;

      &:not(:first-child) {
        margin: 2px 0 2px 8px  !important;
      }
  }
}

md-autocomplete.ember-power-select-trigger:not(.classic){
  border-bottom: none !important;
}

.ember-power-select-options[role="listbox"] {
    max-height: 25.25em !important;
}

.ember-basic-dropdown-content.ember-power-select-dropdown:not(.classic){
  border-color: transparent !important;
  box-shadow: 0px 3px 13px 1px rgba(94, 124, 250, 0.38);
}

.ember-power-select-trigger:not(.classic){
  border-top-width: 0px!important;
  border-left-width: 0px!important;
  border-right-width: 0px!important;

  min-width: 100%;
}

.ember-power-select-search {
    padding: 5px !important;
    display: flex;
    background: #efefef;

    .ember-power-select-search-input {
      flex: 1;
      outline: none;
      color: $primary-color;
      border: 1px solid #dcdcdc !important;
      border-radius: 50px;
      // border-width: 0px 0px 1px 0px !important;
      // width: 100%;
      font-size: 12px;
      line-height: inherit;
      padding: 3px 8px;
    }
}
