.ember-basic-dropdown-trigger.classic.empty{
  padding: 2px 5px;
  // background: green;
}

.ember-basic-dropdown-content.ember-power-select-dropdown.classic{
    // border-color: transparent !important;
    // box-shadow: 0px 3px 13px 1px rgba(94, 124, 250, 0.38);
  }
  
  .ember-power-select-trigger.classic{
    // border-top-width: 0px!important;
    // border-left-width: 0px!important;
    // border-right-width: 0px!important;
  
    min-width: 100%;
    height: 24px;
  }
  .ember-power-select-options.classic{
    .ember-power-select-option[aria-current="true"] {
      background-color: #5897fb;
      color: #000 !important; }
    .ember-power-select-option {
      padding: 2px;
      font-size: 12px;
      font-weight: 500;
    }
  }

