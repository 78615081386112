
.settings-row {
    display: flex;
    align-items: center;
    padding: 10px 6px;
    padding-right: 20px;
    font-size: 16px;

    &:not(:first-child){
      // border-top: 1px solid #eaeaea;
    }

    .sr-icon{
      margin: 0px 10px;
      width: 35px;
      height: 35px;

        *{
          fill:#507cb1;
        }
    }

    .sr-label-wrapper{
      margin-left: 10px;
      .sr-label{
        // font-size: 18px;
      }
    }

    .line{
      flex: 1;
      border-top: 1px dashed #6183a7;
      height: 1px;
      margin: 0px 13px;
    }
    // .sr-content{
    //   justify-content: flex-end;
    //   margin-right: 20px;
    // }

    input, .md-input{
      background: white;
      border-radius: 15px;
      padding-left: 16px;
      box-shadow: 1px 1px 17px rgba(107, 150, 226, 0.30196078431372547);
    }

    md-input-container .md-errors-spacer{
      min-height: 0px !important;
    }
}
