




.halfOfParentWidth{
    // width: calc(45% - 100px) !important;
    
    // background: green;
} 




.stats-item{
    margin: 20px;
    // background: rebeccapurple;
}

.text-value-list{
    // display: flex;
    // flex-direction: row;
    font-size: 14px;
}

.text-value-list td{
    padding: 3px;
}

.text-value-list .text{
    color: #7f8c8d;
    margin-right: 6px;
}

.text-value-list .value{
    color: #454646;
    font-size: 16px;
    font-weight: bold;

}

.text-value{
    display: flex;
    flex-direction: row;
    align-items: center;
}


.tv-text{
    color: #7f8c8d;
    margin: 0px 6px 0px 0px;
    font-size: 14px;

}

.tv-value{
    color: #454646;
    font-size: 16px;
    font-weight: bold;
    margin: 0px 0px 0px 0px;
}
