.report-controls{
  padding: 0px!important;
  // margin-top: 20px;
  margin-bottom: 8px;
  position: relative;
  background: #f8f8f8 !important;
  // background: red;

  .soft-white-button{
    // font-size: 14px;
    padding: 8px 14px;
    margin-left: 10px;
  }

  .rc-content{
    // margin-top: 25px;
    padding: 6px 14px;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .rc-content-group{
      border: 1px solid #b1dcff;
      border-width: 1px 0px 0px 1px;
      border-radius: 6px 0px 0px 6px;
      margin: 5px;
      margin-left: 10px;
      margin-top: 14px;
      padding: 10px;
      padding-top: 16px;
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      // flex: 1;

      .rc-cg-label{
        position: absolute;
        top: 0px;
        left: 0px;
        background: #b1dcff;
        font-weight: 600;
        border-radius: 6px 0px 6px 0px;
        padding: 0px 6px;
        color: #555555;
        white-space: nowrap;
      }

      .rc-control{
        margin-top: 25px;
        min-width: 150px;
        margin-left: 5px;
        // padding-left: 10px;
        // border-left: 1px solid gray;


      }
      md-input-container {
          margin-top: 8px;
          margin-bottom: 0px;
      }

      md-input-container .md-errors-spacer {
          min-height: 0px;
      }

      md-input-container .md-input {
        padding-right: 25px;
      }
    }

  }
}

.rc-dd-wrapper{
  position: absolute;
  display: flex;
  justify-content: center;
  width: fit-content;
  top: -140px;
  height: 200px;
  font-size: 14px;
  .rc-dd{
    background: white;
    margin: 20px;
    padding: 20px;
    box-shadow:  1px 7px 20px 5px #2385ff3b;
    border: 1px solid #d5d5d5;
    z-index: 99;
    display: flex;
    flex-direction: column;
    left: 0;
    width: 100%;
    // width: 100%;

    .rc-header{
      margin-bottom: 10px;
      padding-bottom: 5px;
      width: 100%;
      display: flex;

      .rc-header-content{
        color: #28597b;
        font-weight: 600;
        flex: 1;
        margin-right: 20px;
      }
      .search-input{
        min-width: 280px;
        outline: none;
        border: 1px solid #b2b2b2;
        border-radius: 10px;
        padding: 4px 10px;
        margin-left: 10px;
        margin-right: 10px;
        color: #283c66;
      }

      .rc-close{
        outline: none;
        border: none;
        color: #f47777;
        font-size: 28px;
        position: absolute;
        right: 25px;
        top: 28px;
        background: transparent;

        i{
          margin: 0px;
        }
      }

    }
    .rc-dd-content{
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      overflow: auto;
      flex: 1;
      >*{
        margin: 6px 10px;
      }
    }


  }
}
