// /* Color Theme Swatches in Hex */
// .Stethoscope-with-heart-beat-report-and-doctor-analyzing-checkup-on-laptop-in-health-medical-laboratory-background.-1-hex { color: #2C4C59; }
// .Stethoscope-with-heart-beat-report-and-doctor-analyzing-checkup-on-laptop-in-health-medical-laboratory-background.-2-hex { color: #467F8C; }
// .Stethoscope-with-heart-beat-report-and-doctor-analyzing-checkup-on-laptop-in-health-medical-laboratory-background.-3-hex { color: #75B2BF; }
// .Stethoscope-with-heart-beat-report-and-doctor-analyzing-checkup-on-laptop-in-health-medical-laboratory-background.-4-hex { color: #CEECF2; }
// .Stethoscope-with-heart-beat-report-and-doctor-analyzing-checkup-on-laptop-in-health-medical-laboratory-background.-5-hex { color: #F2F2F2; }

// /* Color Theme Swatches in RGBA */
// .Stethoscope-with-heart-beat-report-and-doctor-analyzing-checkup-on-laptop-in-health-medical-laboratory-background.-1-rgba { color: rgba(43, 76, 89, 1); }
// .Stethoscope-with-heart-beat-report-and-doctor-analyzing-checkup-on-laptop-in-health-medical-laboratory-background.-2-rgba { color: rgba(70, 127, 140, 1); }
// .Stethoscope-with-heart-beat-report-and-doctor-analyzing-checkup-on-laptop-in-health-medical-laboratory-background.-3-rgba { color: rgba(116, 177, 191, 1); }
// .Stethoscope-with-heart-beat-report-and-doctor-analyzing-checkup-on-laptop-in-health-medical-laboratory-background.-4-rgba { color: rgba(205, 235, 242, 1); }
// .Stethoscope-with-heart-beat-report-and-doctor-analyzing-checkup-on-laptop-in-health-medical-laboratory-background.-5-rgba { color: rgba(242, 242, 242, 1); }

// /* Color Theme Swatches in HSLA */
// .Stethoscope-with-heart-beat-report-and-doctor-analyzing-checkup-on-laptop-in-health-medical-laboratory-background.-1-hsla { color: hsla(196, 34, 26, 1); }
// .Stethoscope-with-heart-beat-report-and-doctor-analyzing-checkup-on-laptop-in-health-medical-laboratory-background.-2-hsla { color: hsla(191, 33, 41, 1); }
// .Stethoscope-with-heart-beat-report-and-doctor-analyzing-checkup-on-laptop-in-health-medical-laboratory-background.-3-hsla { color: hsla(191, 36, 60, 1); }
// .Stethoscope-with-heart-beat-report-and-doctor-analyzing-checkup-on-laptop-in-health-medical-laboratory-background.-4-hsla { color: hsla(191, 58, 87, 1); }
// .Stethoscope-with-heart-beat-report-and-doctor-analyzing-checkup-on-laptop-in-health-medical-laboratory-background.-5-hsla { color: hsla(0, 0, 95, 1); }


$primary: "blue";
$accent: "green";
$warn: "pink";


@import "color-palette";
$color-teal: map-merge($color-teal, ('contrastDefaultColor':  $light-contrast-color));
$color-light-green: map-merge($color-light-green, ('contrastDefaultColor':  $light-contrast-color));


@import 'ember-paper';
@import 'config.scss';
@import 'svg.scss';
@import 'basic-css.scss';
@import 'buttons.scss';
@import 'app-toggles.scss';
@import 'components/@component.scss';
@import 'breadcrumb.scss';
@import "ember-basic-dropdown";
@import 'material.scss';
@import 'content-props.scss';
@import 'controls.scss';
@import 'notification.scss';
@import 'magic-check.scss';

@import "modals.scss";
@import "alerts.scss";

@import 'ember-paper-modify.scss';
@import 'ember-models-table.scss';


@import 'tables.scss';

@import 'sign-in.scss';
@import 'hmis-app.scss';
@import 'object-viewer.scss';
@import 'app-header.scss';
@import 'app-menu.scss';
@import 'workspace.scss';
@import 'forms.scss';

@import 'full-calendar.scss';

@import "ember-paper-stepper";

@import 'toolbar.scss';
@import 'quick-actions.scss';
@import 'circle-progress-of.scss';

@import 'pages/.pages.scss';
@import 'app-loading.scss';


body {
  margin: 0;
  padding: 0;
  font: 100% $font-stack;
  background-color: #f1f1f1;

  md-input-container{
    font-size: 14px !important;
  }

  text-transform: lowercase;
  :not(md-icon):first-letter{
    text-transform: capitalize;
    // color: red;
  }

  .unique-text{
    text-transform: initial;
  }
}
.logo-icon{
    width: 100px;
    height: 100px;
}
.app-spinner{
  .loader-app-spinner{
      color: #9c08bf !important;
  }
}

.app-layout {
  display: grid;
  height: 100vh;
  width: 100vw;
    grid-template-areas:
    "app-header app-header app-header app-header app-header"
    "app-menu app-breadcrumbs app-breadcrumbs app-breadcrumbs app-breadcrumbs"
    "app-menu app-mini-menu app-toolbar app-toolbar app-toolbar"
    "app-menu app-mini-menu app-workspace app-mini-workspace app-quick-action"
    "app-footer app-footer app-footer app-footer app-footer"
    ;
    grid-template-columns: auto auto 1fr auto auto;
    // grid-template-rows: $app-header-height $app-breadcrumbs-height $app-toolbar-height 1fr $app-footer-height;

  grid-gap: 0px;
  background-color: #1A2A40;
  padding: 0px;
}

.app-header{
  grid-area: app-header;
}

.app-menu{
  grid-area: app-menu;
    width: $side-menu-width;
    transition: width 0.3s;

    // background-color: $primary-color;
    // background: $primary-gradient-bg;
    // @include primary-gradient-bg-diagonal;
}

 .app-menu-collapsed {
  width: $side-menu-collapsed-width;

  .hmis-side-menu-item-subs{
    margin-left: 0px;
    .hmis-side-menu-item-sub{
      .icon{
        display: none;
      }
      .side-menu-item-sub-text{
        width: 10px;
        font-weight: 100;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

  }
}

.app-footer{
  // grid-area: app-footer;
  background: $primary-white-color;
}

.app-quick-action{
    grid-area: app-quick-action;
    background: $primary-white-color2;;
    width: $app-quick-action-width;
    transition: width 0.2s;
}

.app-breadcrumbs{
  grid-area: app-breadcrumbs;
  background: $primary-white-color2;
}

.app-quick-action-collapsed{
  width: 0px;
}

.app-toolbar{
  width: 100%;
  flex-wrap: wrap;
  text-align: right;
  margin-right: 10px;
}

.app-workspace{
  overflow-y: auto;
  padding: 25px 40px 25px 40px;
  background-color: #F2F2F2
}

.app-workspace-nested{
  overflow-y: auto;
  padding: 25px 40px 25px 0px;
  background-color: #F2F2F2
}

.app-mini-menu{
  grid-area: app-mini-menu;
  // background: $mini-menu-background;
  @include primary-gradient-bg-lt;
  overflow-y: auto;
}

.app-mini-workspace{
  grid-area: app-mini-workspace;
  overflow-y: auto;
  width: $app-mini-workspace-width;
  background: $primary-white-color2;
  padding: 14px;
  // background: red;
  z-index: 10;
  position: relative;

  &.amw-when-closed{
    width: 0px !important;
    box-shadow: none;
    overflow: visible;
  }

  @media (max-width: $tablet-bp) { 
    position: fixed;
    top: $app-header-height;
    right: 0;
    background: transparent;
    height: calc( #{100%} - calc(#{$app-header-height} + #{$app-footer-height}) );
    // margin-bottom: $app-footer-height;

    &>.stats-container{
      border-radius: $container-border-radius;
      box-shadow: $box-shadow;
    }
  }
  @media (min-width: $tablet-bp) { 
    &.amw-when-opened{
      padding-left: 0px;
    }
  }



  &.on-init.is-opened{
    @media (max-width: $tablet-bp) { 
      &.amw-when-closed{
        display: block;
      }
      &.amw-when-opened{
        display: none;
      }
    }
  }
  &.is-closed{
    &.amw-when-closed{
      display: block;
    }
    &.amw-when-opened{
      display: none;
    }
  }
  &.is-opened{
    &.amw-when-closed{
      display: none;
    }
    &.amw-when-opened{
      display: block;
    }
  }


}

.app-breadcrumbs .breadcrumb{
  font-size: 14px;
}

.app-loadings{
  *{
    height: 2.4px !important;
  }
}

.ember-power-select-options:not(.classic){
  .ember-power-select-option[aria-current="true"] {
    background-color: #5897fb;
    color: #000 !important; }
}

.ember-power-select-group .ember-power-select-group-name{
  font-size: 14px !important;
}

.allergy-input{
  border-radius: 500rem;
    width: 300px;
    margin: 0;
    outline: none;
    -webkit-appearance: none;
    text-shadow: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2142em;
    padding: .67861429em 1em;
    font-size: 12px;
    background: #FFF;
    border: 1px solid rgba(34, 36, 38, 0.15);
    color: rgba(0, 0, 0, 0.87);
    // -webkit-box-shadow: 0 0 0 0 transparent inset;
    // box-shadow: 0 0 0 0 transparent inset;
    // -webkit-transition: background-color .1s ease,color .1s ease,box-shadow .1s ease,border-color .1s ease;
    // -webkit-transition: background-color .1s ease,color .1s ease,border-color .1s ease,-webkit-box-shadow .1s ease;
    // transition: background-color .1s ease,color .1s ease,border-color .1s ease,-webkit-box-shadow .1s ease;
    // transition: background-color .1s ease,color .1s ease,box-shadow .1s ease,border-color .1s ease;
    // transition: background-color .1s ease,color .1s ease,box-shadow .1s ease,border-color .1s ease,-webkit-box-shadow .1s ease;
}

.occupation-radio{
  display: inline-flex;
  font-size: 13px;
}

.occu_radio{
  margin-left: 20px
}

.allergy-checkbox-width{
  width: 30px;
}

.sharing_content{
  height: 100% !important;
  background: #FFF;
  -webkit-box-shadow: 0px 0px 14px rgba(10, 100, 182, 0.141176);
  box-shadow: 0px 0px 14px rgba(10, 100, 182, 0.141176);
}
.md-steppers-header, .md-steppers-mobile-header,.md-step-actions{
  text-align: right !important;
}
.step-actions{
  text-align: right !important;
  margin: 8px;
}



.tools-group {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}


a{
  color: #467F8C;
}

a:hover{
  color: #75B2BF;
}


@import "ember-power-select";
