
.circle-progress-of{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 2em;
    width: fit-content;
    flex-wrap: wrap;
    margin: 6px;
}

.circle-progress-of .progress{
    width: 80px;
    height: 80px;
    background: transparent;
    -webkit-box-shadow: 0 3px 5px transparent;
	-moz-box-shadow: 0 3px 5px transparent;
    box-shadow: 0 3px 5px transparent;
    font-size: 17px;
    padding: 5px;
    margin: 0px;
    white-space: nowrap;
}

.circle-progress-of .text-container{
    margin-left: 6px;
    color: $dark-text;

    white-space: nowrap;
}

.circle-progress-of .text{
    font-size: 18px;
    margin: 0px;
}

.circle-progress-of .info{
    font-size: 10px;
    margin: 0px;
}
