
$arrow-size:16px;
$top-height:36px;
$menu-item-icon-size:25px;

.close{
  width: 0px;
}

.hmis-side-menu{
  width: 100%;
    padding: 0px 0px 10px 2px;
    display: flex;
    height: 100%;

    flex-direction: column;
  }

  .hmis-side-menu .top{
   flex-grow: 0;
    height: $top-height;
  }

  .hmis-side-menu .menus{
    height: 100%;
    flex-grow: 1;
    overflow-y:auto;
    overflow-x:hidden;
    // height: calc(100% - #{$top-height})
  }
  .hmis-side-menu .menus::-webkit-scrollbar {
    // display: none;
  }

  .hmis-side-menu .active{
    background: rgba(255, 255, 255, 0.18)
  }

.hmis-side-menu-item{
    height: $side-menu-item-height;
    padding: 15px 8px;
    // display: grid;
    align-items: center;
      grid-template-areas:
      "icon text arrow count";
      grid-template-columns: $menu-item-icon-size auto $arrow-size 18px;
      grid-column-gap: 6px;
      cursor:pointer;
      white-space: nowrap;
    //   grid-template-rows: $top-bar-height auto;
    position: relative;

    .menu-badge{
      left:initial !important;
      right: 5px !important;
      top: 1px !important;
    }

  
  }
  .hmis-side-menu-item:hover{
    background: rgba(255, 255, 255, 0.122);
  } 

  .hmis-side-menu-item .icon g, .hmis-side-menu-item .icon path{
      fill:white;
  }

  
  
  .side-menu-item-icon{ grid-area: icon;
    height: 100%
  }
  .side-menu-item-text { grid-area: text;
    color: #2C4C59;
    font-size: 15px;
    margin-left: 6px;
    text-transform: initial;
  }
  .side-menu-item-arrow { grid-area: arrow;
    height: $arrow-size;
    border-radius: 50%;}

    .side-menu-item-arrow > i{
        height: 100%;
        width: 100%;
        font-size:18px; 
        color:#d3dbe5;
        // padding: 0px 0px 9px 0px;
        // background: red;
    }
    .side-menu-item-arrow > i:hover{
        color:$primary-color;
    }

  .side-menu-item-arrow:hover { 
      background: $primary-white-color;
  }

  .side-menu-item-count { grid-area: count;
    color: $primary-color;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 50%;
    height: 18px;
 }






///////////////////////
//side-menu-item-sub
///////////////////////

.hmis-side-menu-item-sub{
  height: $side-menu-item-sub-height;
  padding: 4px 4px;
  display: flex;
  align-items: center;
  color: white;
    cursor:pointer;

    .side-menu-item-sub-text{
      margin-left: 6px;
      font-size: 14px;
    }
  //   grid-template-rows: $top-bar-height auto;
}

.hmis-side-menu-item-sub:hover{
  background: $primary-light-color2;
}
.hmis-side-menu-item-subs{
  overflow-y: hidden;
  overflow-x: hidden;
  max-height: 0;
  margin:2px 2px 2px 29px;
  transition: max-height 0.2s ease-out;
  /*white-space: nowrap;*/
}

.hmis-side-menu-item-subs-showing{
    max-height: 500px;
    transition: max-height 0.2s ease-in;
}
