
@mixin app-button{
    font-size: 12px;
    border-radius: $edge-radius;
    border-style: none;
    outline: none;
    &.disabled , :disabled{
        background: #e9edf2;
        color: #3d3c3c;
    }
}

@mixin gray-button{
    background: $gray-button-background;
    color: $gray-button-forecolor;
    padding: 6px;
    text-decoration: none;
}


@mixin outline-button{
    border-radius: $edge-radius!important;
    border-style: solid!important;
    border-width: 1px!important;
    outline: none;
    background: white !important;
    &:disabled{
        filter: grayscale(0.6);
    }
}

@mixin fill-button{
    border-radius: $edge-radius!important;
    outline: none;
    color: white !important;
    border:none;

    &:hover{
      filter: opacity(.8);
    }
}

@mixin primary-outline{
    color: $primary-color;
    border-color: $primary-color;
    &:hover{
        background: $primary-color !important;
        color: white;
    }
}

.primary-button{
    @include app-button;
    background: $primary-color;
    color: white;
    padding: 6px;
    text-decoration: none;
}

.primary-button:hover{
    background: $primary-light-color
}
@mixin soft-white-btn{
  outline: none;
  color: #5286d3 !important;
  border: none;
  background: #ffffff;
  box-shadow: 0px 0px 14px 0px #a7c7f699;
  font-size: 14px;
  padding: 6px;
  &:disabled{
    background: #f5f5f5;
    box-shadow:none;
    color: gray !important;
  }

  &:hover:not(:disabled){
    background: #fcfdff;
  }

  i{
    margin-left: 10px;
  }
}

.soft-white-button{
  border-radius: 15px !important;
  margin-right: 10px;
  @include soft-white-btn;
}

.gray-button{
    @include app-button;
    @include gray-button;
}

.gray-button:hover{
    background: #f3f7f8;
}

.outline-button{
    // @include app-button;

    @include outline-button;
}

.primary-outline{
    // color: $primary-color;
    // border-color: $primary-color;
    @include primary-outline;
}

// .primary-outline:hover{
//     background: $primary-color;
//     color: white;
// }

.green-fill{
    background: $green-color !important;
    // border-color: $green-color;
}

.green-outline{
    @include outline-button;
    color: $green-color !important;
    border-color: $green-color !important;
}

.green-outline:hover:not(:disabled){
    color: white!important;
    background: $green-color!important;
}

.red-fill{
    background: $red-color !important;
    // border-color: $red-color;
}

.red-outline{
    @include outline-button;
    color: $red-color !important;
    border-color: $red-color !important;
}

.red-outline:hover:not(:disabled){
    color: white!important;
    background: $red-color!important;
}

.blue-outline{
  @include outline-button;
    color: $blue-color;
    border-color: $blue-color;
}

.blue-fill{
  @include fill-button;
    background: $blue-color;
}


.blue-outline:hover:not(:disabled){
    color: white;
    background: $blue-color!important;
}

.submit-button {
    -webkit-border-radius: 5;
    -moz-border-radius: 5;
    border-radius: 10px;
    border-width: 0px;
    color: #ffffff !important;
    font-size: 14px;
    margin-bottom: 10px;
    // background:$primary-color;
    @include primary-gradient-bg-diagonal2;
    padding: 13px 24px;
    text-decoration: none;
    font-weight: 400;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    outline: none;
    white-space: nowrap;
    justify-content: center;
    // box-shadow: 3px 3px 13px 1px rgba(43, 76, 89, 0.369);
    position: relative;
    overflow: hidden;

    // &:not(:disabled):hover{
    //     filter: brightness(1.2);
    // }
    &:not([disabled]):hover {
        background: $primary-gradient-bg2 !important; //$primary-gradient-bg-img
        // @include primary-gradient-bg-diagonal2;
        filter: brightness(1.1);
        text-decoration: none;
    }
    .waiting-text{
      // color: #00306f;
    }
    .waiting-progress{
      position: absolute;
          opacity: 0.3;
      height: 100%;
      *{
        height: 100% !important;
      }
    }

  }

  .submit-button.mini{
    font-size: 12px;
    padding: 11px 17px;
  }
  .submit-button.small {
    font-size: 12px;
    padding: 5px 11px;
  }

.submit-button:disabled,
.submit-button[disabled] {
      opacity: 0.5;
    // background: #E9EDF2;
    color: #9c9c9c;
  }

//   .submit-button:not([disabled]):hover {
//     // background: $primary-light-color;
//     text-decoration: none;
//   }

  .submit-button i {
    margin-left: 20px;
  }

  .input-chooser{
    // background: #ebe9e9;
    background: #ebe9e991;
    border: none;
    outline: none;
    border-radius: 0px 6px 6px 0px;
    padding: 6px !important;
    margin-top: 5px;
    margin-right: 6px;
    width: 32px;
    height: 32px;

    path{
        fill: #0e83dd;
    }

    &:disabled{
        background: #f1f1f1;
        path{
            fill: #adacac;
        }
    }

    &:hover:not(:disabled){
        background: #e2e9fe;
    }
  }
